import React from "react";
import { auth } from "../../../firebase";

function Reward() {
  return (
    <div>
      <h1 className="ml-4 lg:ml-0 mb-4 text-[16px] text-slate-600">Reward</h1>
      <div className="flex items-center">
        <div className="flex items-center justify-center w-[200px] h-[60px] bg-[#3b69fd] text-center rounded shadow">
          <div>
            <p className="text-white">Saldo</p>
            <p className=" text-white text-[16px] font-semibold">
              Rp. 2.320.000,-
            </p>
          </div>
        </div>
        <button className="mx-5 border border-[#3b69fd] text-[#3b69fd] text-xs font-semibold h-[34px] px-5 rounded-md hover:shadow-md">
          Tarik saldo
        </button>
      </div>

      <div className="max-w-[980px] mx-auto my-0">
        <div className="my-5">
          <p className="mb-1">URL undangan Anda:</p>
          <p className="text-blue-400 break-words">
            {`https://beembingan.com/register/${auth.currentUser.uid}`}
          </p>
        </div>
        <div className="bg-white p-4 rounded-md shadow">
          <p className="text-gray-500 mb-4">Detail Akun Penarikan</p>
          <div className="grid grid-cols-4 gap-4">
            <div className="bg-blue-100 border-2 border-blue-300 p-4 rounded-lg">
              <p className="text-slate-500 font-semibold mb-1">Bank Mandiri</p>
              <p>165005642111</p>
            </div>
            <div className="bg-blue-100 border-2 border-blue-300 p-4 rounded-lg">
              <p className="text-slate-500 font-semibold mb-1">OVO</p>
              <p>081564578766</p>
            </div>
            <div className="bg-blue-100 border-2 border-blue-300 p-4 rounded-lg">
              <p className="text-slate-500 font-semibold mb-1">QRIS</p>
              <p>081564578766</p>
            </div>
          </div>
        </div>
        <p className=" text-slate-600 mt-4">Riwayat Penarikan</p>
      </div>
    </div>
  );
}

export default Reward;
