import React from "react";
import useBerandaMhs from "./userBerandaMhs";
function BerandaMhs() {
  const { isLoading, data } = useBerandaMhs();

  const renderCapaianBox = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    const activeIndex = data.data.capaian.findIndex(
      (item) => item.active === true
    );

    return data.data.capaian.map((item, index) => {
      return (
        <div
          key={item.title}
          className={`${
            index <= activeIndex ? "bg-biru" : "bg-gray-300"
          } h-[5px] rounded-full`}
          title={item.title}
        ></div>
      );
    });
  };
  const renderCapaianGrid = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    return (
      <div
        className={`grid grid-cols-${data.data.capaian.length} gap-1 w-full mb-4  pb-3`}
      >
        {renderCapaianBox()}
      </div>
    );
  };
  const renderCapaian = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    const activeCapaian = data.data.capaian.filter(
      (item) => item.active === true
    );
    return <p className="pb-2 text-gray-500">{activeCapaian[0].title}</p>;
  };
  const renderCatatan = () => {
    if (!data.data.catatan || data.data.catatan.length < 1) {
      return;
    }

    return data.data.catatan.map((item) => {
      return (
        <li key={item} className="">
          {item}
        </li>
      );
    });
  };

  if (isLoading) {
    return <p className="lg:w-1/2">Loading...</p>;
  }

  return (
    <div>
      {data.length < 1 ? (
        <p>Belum ada data bimbingan</p>
      ) : (
        <div className="lg:grid grid-cols-2 gap-8">
          <div className="rounded-xl bg-white p-5 mb-4 lg:mb-0">
            <h1 className="mb-2">Capaian Anda:</h1>
            {data && renderCapaian()}
            {data && renderCapaianGrid()}
          </div>
          <div className="rounded-xl bg-white p-5">
            <p className="mb-2">Catatan dari Dosen:</p>
            {data && renderCatatan()}
          </div>
        </div>
      )}
    </div>
  );
}

export default BerandaMhs;
