import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { auth } from "../../../firebase";

function usePengajuanDosen() {
  const [idtobeDeleted, setIdtobeDeleted] = useState("");
  const [idtobeAccepted, setIdtobeAccepted] = useState("");

  const fetchPengajuan = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}pengajuan/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        role: "dosen",
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["fetch-pengajuan"],
    queryFn: fetchPengajuan,
  });

  const tolakPengajuan = async (mahasiswaid) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}pengajuan/tolak`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mahasiswaid,
      }),
    });
    const da = await uu.json();
    if (da.error) {
      throw new Error(da.message);
    } else {
      return da;
    }
  };
  const { isPending, mutate } = useMutation({
    onMutate: (mahasiswaid) => {
      setIdtobeDeleted(mahasiswaid);
    },
    mutationFn: tolakPengajuan,
    onSuccess: () => {
      setIdtobeDeleted("");
      refetch();
    },
    onError: (error) => {
      setIdtobeDeleted("");
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });
  const terimaPengajuan = async (mahasiswaid) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}pengajuan/terima`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mahasiswaid,
      }),
    });
    const da = await uu.json();
    if (da.error) {
      throw new Error(da.message);
    } else {
      return da;
    }
  };
  const { isPending: isPendingTerima, mutate: mutateTerima } = useMutation({
    onMutate: (mahasiswaid) => {
      setIdtobeAccepted(mahasiswaid);
    },
    mutationFn: terimaPengajuan,
    onSuccess: () => {
      setIdtobeAccepted("");
      refetch();
    },
    onError: (error) => {
      setIdtobeAccepted("");
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });

  return {
    data,
    isPending,
    mutate,
    idtobeDeleted,
    idtobeAccepted,
    isPendingTerima,
    mutateTerima,
    isLoading,
  };
}

export default usePengajuanDosen;
