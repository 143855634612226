import React, { useContext } from "react";
import UserContext from "../../../context/userContext";
import JadwalMhs from "./JadwalMhs";
import JadwalDosen from "./JadwalDosen";
function Jadwal() {
  const { user } = useContext(UserContext);

  if (user.role === "mahasiswa") {
    return <JadwalMhs />;
  }
  if (user.role === "dosen") {
    return <JadwalDosen />;
  }
}

export default Jadwal;
