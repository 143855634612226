import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBHZX8IjbVqkwJ8xHEgIddJy0VEoYmZEwA",
  authDomain: "tatrakker-67926.firebaseapp.com",
  projectId: "tatrakker-67926",
  storageBucket: "tatrakker-67926.appspot.com",
  messagingSenderId: "218059219309",
  appId: "1:218059219309:web:8bb46e102a85d5f05b7a64",
  measurementId: "G-TSMJGDZVJF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//eslint-disable-next-line
const analytics = getAnalytics(app);
//authentication
const auth = getAuth();
const googleLogin = new GoogleAuthProvider();
const facebookLogin = new FacebookAuthProvider();
const twitterLogin = new TwitterAuthProvider();

//Firestore Collection (DB)
const db = getFirestore(app);
const storage = getStorage();

export { auth, googleLogin, facebookLogin, twitterLogin, db, storage };
