import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  getDocs,
  collection,
  query,
  limit,
  orderBy,
  startAfter,
  endAt,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";

function useNotifikasi() {
  const [data, setData] = useState([]);
  const [lastView, setLastView] = useState("");

  useEffect(() => {
    const fetchNotifikasi = async () => {
      const first = query(
        collection(
          db,
          process.env.REACT_APP_USER,
          auth.currentUser.uid,
          "notification"
        ),
        orderBy("created", "desc"),
        limit(20)
      );
      const documentSnapshots = await getDocs(first);
      const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      const flatten = [];
      documentSnapshots.docs.forEach((item) => {
        // console.log(item.data().created.toDate().toLocaleString());
        flatten.push({
          ...item.data(),
          id: item.id,
          created: item.data().created.toDate().toISOString(),
        });
      });
      // console.log(flatten);
      setData(flatten);
      setLastView(lastDoc);
    };
    fetchNotifikasi();
    //eslint-disable-next-line
  }, []);

  const fetchNextPage = async () => {
    if (!lastView) {
      return;
    }
    const next = query(
      collection(
        db,
        process.env.REACT_APP_USER,
        auth.currentUser.uid,
        "notification"
      ),
      orderBy("created", "desc"),
      startAfter(lastView),
      limit(20)
    );
    const documentSnapshots = await getDocs(next);
    const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    const flatten = [];
    documentSnapshots.docs.forEach((item) => {
      flatten.push({
        ...item.data(),
        id: item.id,
        created: item.data().created.toDate().toLocaleString(),
      });
    });
    setData((prev) => [...prev, ...flatten]);
    setLastView(lastDoc);
  };

  const fetchAfterDelete = async () => {
    const first = query(
      collection(
        db,
        process.env.REACT_APP_USER,
        auth.currentUser.uid,
        "notification"
      ),
      orderBy("created", "desc"),
      endAt(lastView)
    );
    const documentSnapshots = await getDocs(first);
    const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    const flatten = [];
    documentSnapshots.docs.forEach((item) => {
      flatten.push({
        ...item.data(),
        id: item.id,
        created: item.data().created.toDate().toLocaleString(),
      });
    });
    setData(flatten);
    setLastView(lastDoc);
  };

  const fetchDeleteNotif = async (ids) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}notifikasi/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { mutate } = useMutation({
    mutationFn: fetchDeleteNotif,
    onSuccess: () => {
      fetchAfterDelete();
    },
  });

  return {
    mutate,
    data,
    fetchNextPage,
  };
}

export default useNotifikasi;
