import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
// import { Timestamp } from "firebase/firestore";

import { auth } from "../../../firebase";
import UserContext from "../../../context/userContext";

function useBilling() {
  const { setAksesValid } = useContext(UserContext);
  const useKupon = async (kuponid) => {
    const token = await auth.currentUser.getIdToken();
    const fetchUser = await fetch(`${process.env.REACT_APP_API}kupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        kuponid,
      }),
    });
    const data = await fetchUser.json();
    if (data.error) {
      throw new Error(data.message);
    } else {
      return data;
    }
  };
  //   console.log(user);
  const { isPending, mutate } = useMutation({
    mutationFn: useKupon,
    onSuccess: () => {
      toast.success("Kupon berhasil digunakan", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAksesValid(true);
    },
    onError: (error) => {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });

  return {
    isPending,
    mutate,
  };
}

export default useBilling;
