import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import UserContext from "./context/userContext";
import SetProfile from "./SetProfile";

const PrivateRoute = () => {
  const { user, isSignedIn, loading } = useContext(UserContext);
  if (loading) {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <p className="text-center font-bold text-gray-500">LOADING...</p>
      </div>
    );
  }

  if (!loading && isSignedIn) {
    //in the db and is logged in
    if (user) {
      return <Outlet />;
    } else {
      return <SetProfile />;
    }
  }
  if (!loading && !isSignedIn) {
    return <Navigate to="/register" />;
  }
};

export default PrivateRoute;
