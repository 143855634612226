import React from "react";

function Arsip() {
  return (
    <div>
      <p>Arsip</p>
    </div>
  );
}

export default Arsip;
