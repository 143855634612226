import React from "react";

import useSignup from "./useSignup";
import { style } from "../style";
import mail from "../../assets/icon_mail.png";
import password from "../../assets/password.png";
import spinner from "../../assets/spinner.gif";

function SignupForm({ setError }) {
  const { handleSignup, handleSetUser, isPending } = useSignup(setError);

  return (
    <form onSubmit={handleSignup} className="w-72 lg:w-96 mx-auto my-0">
      <div className="relative mb-3">
        <img
          className="absolute left-4 top-1/2 -translate-y-1/2"
          src={mail}
          alt="mail"
        />
        <input
          className="border w-full border-gray-300 pl-16 rounded p-4"
          type="text"
          placeholder="Email"
          required
          onChange={(e) => handleSetUser(e.target.value, "email")}
        />
      </div>
      <div className="relative mb-3">
        <img
          className="absolute left-4 top-1/2 -translate-y-1/2"
          src={password}
          alt="key"
        />
        <input
          className="border w-full border-gray-300 pl-16 rounded p-4"
          type="password"
          placeholder="Kata sandi"
          required
          onChange={(e) => handleSetUser(e.target.value, "password")}
        />
      </div>
      <button type="submit" className={style.btnSubmit}>
        {isPending ? (
          <img className="w-5 mx-auto my-0" src={spinner} alt="" />
        ) : (
          "Daftar"
        )}
      </button>
    </form>
  );
}

export default SignupForm;
