import React, { useState, useContext, useRef } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import UserContext from "../../../context/userContext";

import useBimbinganDosen from "./useBimbinganDosen";
import setting from "../../../assets/icon_eq.png";
import spinner from "../../../assets/spinner.gif";
import chat from "../../../assets/icon_chat.png";
import close from "../../../assets/icon_close_gray.png";
import { auth } from "../../../firebase";

function BimbinganDosen() {
  const { setShowChat } = useContext(UserContext);
  const {
    data,
    dataMhs,
    mutate,
    isPending,
    selectedMahasiswaId,
    setSelectedMahasiswaId,
    pendingUpdate,
    mutateUpdate,
    isSuntingCapaian,
    setIsSuntingCapaian,
    templateList,
    setTemplateList,
    isLoading,
    catatan,
    setCatatan,
    pendingCatatan,
    mutateCatatan,
    setFile,
    pendingUpload,
    mutateUpload,
    fileUrl,
    setFileUrl,
  } = useBimbinganDosen();
  const inputRef = useRef(null);

  const [activeTitle, setActiveTitle] = useState(false);
  const [hoveredId, setHoveredId] = useState("");
  const [editingId, setEditingId] = useState("");
  const [catatanAktif, setCatatanAktif] = useState("");
  const [editCatatanItem, setEditCatatanItem] = useState("");
  const [catatanPengganti, setCatatanPengganti] = useState("");

  const createOptions = () => {
    // console.log(data);
    if (!data) return;
    const dataFilter = [];
    data.forEach((item) => {
      const obj = {
        value: item.id,
        label: item.nama,
      };
      dataFilter.push(obj);
    });
    return dataFilter;
  };

  const renderJudul = () => {
    return (
      <p className="mb-4 text-gray-500  border-b border-gray-200 pb-3 rounded-md">
        {dataMhs.data().judul}
      </p>
    );
  };

  const renderDoing = () => {
    return (
      <p className="mb-4 text-gray-500  border-b border-gray-200 pb-3 rounded-md">
        {dataMhs.data().doing}
      </p>
    );
  };

  const renderCapaianBox = () => {
    if (!dataMhs.data().capaian || dataMhs.data().capaian.length < 1) {
      return;
    }
    const activeIndex = dataMhs
      .data()
      .capaian.findIndex((item) => item.active === true);

    return dataMhs.data().capaian.map((item, index) => {
      return (
        <div
          key={item.id}
          className={`${
            index <= activeIndex ? "bg-biru" : "bg-gray-300"
          } h-[5px] rounded-full`}
          title={item.title}
        ></div>
      );
    });
  };

  const renderCapaianGrid = () => {
    if (!dataMhs.data().capaian || dataMhs.data().capaian.length < 1) {
      return;
    }
    return (
      <div
        className={`grid grid-cols-5 gap-1 w-full mb-4 border-b border-gray-200 pb-3`}
      >
        {renderCapaianBox()}
      </div>
    );
  };

  const renderCapaian = () => {
    if (!dataMhs.data().capaian || dataMhs.data().capaian.length < 1) {
      return;
    }
    const activeCapaian = dataMhs
      .data()
      .capaian.filter((item) => item.active === true);

    return <p className="pb-2 text-gray-500">{activeCapaian[0].title}</p>;
  };

  const renderDocUrl = () => {
    if (!dataMhs.data().docsUrl || dataMhs.data().docsUrl.length < 1) {
      return;
    }
    const arr = dataMhs.data().docsUrl[dataMhs.data().docsUrl.length - 1];
    return (
      <div>
        <Link
          target="_blank"
          to={arr.docUrl}
          className="underline text-blue-400"
        >
          {arr.name}
        </Link>
        <p className="text-xs">diunggah oleh: {arr.uploadedBy}</p>
      </div>
    );
  };

  const renderCapaianTemplate = () => {
    return templateList.map((item, idx) => {
      return (
        <div
          key={item.id}
          onMouseOver={() => setHoveredId(item.id)}
          onMouseLeave={() => setHoveredId("")}
          className="hover:border border-blue-300 rounded py-1 px-3"
        >
          <label className="flex items-center cursor-pointer relative w-auto">
            {editingId === item.id ? (
              <div>
                <input
                  type="text"
                  className="px-2 py-1 rounded"
                  onChange={(e) => setActiveTitle(e.target.value)}
                />
                <button
                  onClick={() => setEditingId("")}
                  className="border border-[#3b69fd] text-[#3b69fd] px-2 py-1 ml-2 rounded"
                >
                  batal
                </button>
                <button
                  onClick={() => {
                    if (!activeTitle) return;
                    const list = templateList;
                    list[idx].title = activeTitle;
                    setActiveTitle("");
                    setTemplateList(list);
                    setEditingId("");
                  }}
                  className="bg-biru text-white px-2 py-1 ml-2 rounded"
                >
                  masukkan
                </button>
              </div>
            ) : (
              <>
                <input
                  onChange={(e) => {
                    const list = templateList;
                    const arr = [];
                    list.forEach((item) => {
                      arr.push({
                        id: item.id,
                        title: item.title,
                        active: false,
                      });
                    });
                    arr[idx].active = e.target.checked;
                    setTemplateList(arr);
                  }}
                  // defaultChecked={templateList[idx].active}
                  checked={item.active}
                  name="radio"
                  type="radio"
                  className="mr-2"
                />
                <span>{item.title}</span>
                {hoveredId === item.id && (
                  <button
                    onClick={() => setEditingId(item.id)}
                    className="absolute top-0 right-0 z-10 text-gray-500 bg-white rounded px-2 shadow hover:bg-[#3b69fd] hover:text-white"
                  >
                    edit
                  </button>
                )}
              </>
            )}
          </label>
        </div>
      );
    });
  };

  const renderCatatan = () => {
    if (catatan.length < 1) {
      return;
    }
    return catatan.map((item, idx) => {
      if (editCatatanItem === item) {
        return (
          <div key={item}>
            <input
              onChange={(e) => setCatatanPengganti(e.target.value)}
              value={catatanPengganti}
              type="text"
              className="bg-gray-100 px-2 py-1 border rounded"
            />
            <button
              onClick={() => setEditCatatanItem("")}
              className="border border-[#3b69fd] text-[#3b69fd] px-2 py-1 ml-2 rounded text-xs"
            >
              batal
            </button>
            <button
              onClick={() => {
                const list = catatan.filter((el) => el !== item);
                setCatatan(list);
                setEditCatatanItem("");
              }}
              className="bg-rose-400 px-2 py-1 text-white rounded border ml-2 text-xs border-rose-400"
            >
              hapus
            </button>
            <button
              onClick={() => {
                const list = catatan;
                list[idx] = catatanPengganti;
                setCatatan(list);
                setEditCatatanItem("");
              }}
              className="bg-biru px-2 py-1 text-white rounded border ml-2 text-xs border-[#3b69fd]"
            >
              ganti
            </button>
          </div>
        );
      } else {
        return (
          <li
            onClick={() => {
              if (
                dataMhs &&
                dataMhs.data().dosenUtama !== auth.currentUser.uid
              ) {
                return;
              }
              setEditCatatanItem(item);
              setCatatanPengganti(item);
            }}
            key={item}
            className="cursor-pointer mb-3 hover:text-blue-400"
          >
            {item}
          </li>
        );
      }
    });
  };
  if (isLoading) {
    return <p className="lg:w-1/2">Loading...</p>;
  }
  return (
    <>
      {data ? (
        <div className=" lg:w-1/2">
          <div className="bg-white rounded-xl p-8 relative overflow-y-auto min-h-[350px]">
            <div
              className={`${
                isSuntingCapaian ? "block" : "hidden"
              } absolute top-0 left-0 rounded-xl p-8 z-10 bg-gray-100 w-full h-full`}
            >
              <div className="flex items-center justify-between mb-4">
                <p>Sunting capaian {selectedMahasiswaId.label}</p>
                <p
                  onClick={() => setIsSuntingCapaian(false)}
                  className="bg-white px-4 py-2 text-xs font-semibold rounded hover:shadow-md cursor-pointer"
                >
                  Tutup
                </p>
              </div>

              <div>
                {renderCapaianTemplate()}
                <div className="flex justify-end mt-3">
                  <button
                    onClick={() => {
                      if (!selectedMahasiswaId.value) return;
                      mutateUpdate(templateList);
                    }}
                    className="bg-biru text-white w-[100px] h-[34px] rounded hover:shadow-lg"
                  >
                    {pendingUpdate ? (
                      <img
                        className="w-[14px] mx-auto my-0"
                        src={spinner}
                        alt="menunggu"
                      />
                    ) : (
                      "Simpan"
                    )}
                  </button>
                </div>
              </div>
            </div>
            {data && data.length > 0 && (
              <Select
                defaultValue={{
                  value: data[0].id,
                  label: data[0].nama,
                }}
                value={selectedMahasiswaId}
                options={createOptions()}
                placeholder="Pilih mahasiswa"
                className=" text-[16px]"
                onChange={(e) => {
                  // unsubChat.current && unsubChat.current();
                  setSelectedMahasiswaId({
                    value: e.value,
                    label: e.label,
                  });
                  mutate(e.value);
                }}
              />
            )}
            {isPending && <p className="absolute top-1 right-1">Loading...</p>}

            <>
              <div className="mt-4">
                <h1 className="font-semibold text-gray-500 mb-1">Judul</h1>
                {dataMhs && renderJudul()}
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <h1 className="font-semibold text-gray-500 mb-1">Capaian</h1>
                  {dataMhs &&
                    dataMhs.data().dosenUtama === auth.currentUser.uid && (
                      <img
                        onClick={() => setIsSuntingCapaian(true)}
                        src={setting}
                        alt=""
                        className="cursor-pointer w-[18px]"
                      />
                    )}
                </div>

                {dataMhs && renderCapaian()}
                {dataMhs && renderCapaianGrid()}
              </div>
              <div>
                <h1 className="font-semibold text-gray-500 mb-1">
                  Sedang Dikerjakan
                </h1>
                {dataMhs && renderDoing()}
              </div>
              <div>
                <h1 className="font-semibold text-gray-500 mb-1 mt-3">
                  Dokumen Terbaru
                </h1>
                {dataMhs && renderDocUrl()}
                {dataMhs &&
                  dataMhs.data().dosenUtama === auth.currentUser.uid && (
                    <>
                      <div className="relative border border-gray-200 p-1 rounded-md mt-4 w-full">
                        <button className="py-2">dd</button>
                        <img
                          src={close}
                          alt="cancel"
                          className="absolute top-3 right-3 z-30 cursor-pointer"
                          onClick={() => {
                            setFile("");
                            inputRef.current.value = null;
                          }}
                        />
                        <label
                          htmlFor="upload"
                          className="top-[6px] left-1 absolute z-20 cursor-pointer bg-[#2690f2] text-white py-2 px-4 rounded text-xs inline-block hover:shadow-lg"
                        >
                          Pilih dokumen
                        </label>

                        <input
                          ref={inputRef}
                          id="upload"
                          type="file"
                          className="custom-doc-input top-[8px] absolute left-2 lg:left-3 z-10 w-full"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                      <div className="mt-2">
                        <p className="mb-1 text-xs text-slate-500">
                          Atau masukkan tautan (mis. Google Docs/Drive)
                        </p>
                        <input
                          type="text"
                          placeholder="Tempel tautan"
                          className="bg-gray-100 p-2 w-full text-[16px]"
                          onChange={(e) => setFileUrl(e.target.value)}
                          value={fileUrl}
                        />
                      </div>
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={() => {
                            if (pendingUpload) return;
                            mutateUpload();
                          }}
                          className="bg-biru text-white w-[100px] h-[34px] rounded text-xs font-semibold hover:shadow-lg"
                        >
                          {pendingUpload ? (
                            <img
                              className="w-[14px] mx-auto my-0"
                              src={spinner}
                              alt="menunggu"
                            />
                          ) : (
                            "Unggah"
                          )}
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </>
          </div>
          <div className="bg-white p-8 pb-2 rounded-xl mt-4 max-h-[30vh] overflow-y-auto relative">
            <h1 className="font-semibold text-gray-500">Catatan singkat</h1>
            {dataMhs && dataMhs.data().dosenUtama === auth.currentUser.uid && (
              <p className="text-xs mb-2">
                Klik aitem untuk melakukan perubahan
              </p>
            )}
            <div>{dataMhs && renderCatatan()}</div>
          </div>
          {dataMhs && dataMhs.data().dosenUtama === auth.currentUser.uid && (
            <>
              <div className="items-center bg-white pb-2 border-t border-gray-200 pt-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!dataMhs || !catatanAktif) return;
                    setCatatan((prev) => [...prev, catatanAktif]);
                    setCatatanAktif("");
                  }}
                  className="px-2 flex items-center"
                >
                  <input
                    type="text"
                    placeholder="Isi catatan"
                    className="bg-gray-100 rounded-md p-2 mr-4 w-full block"
                    value={catatanAktif}
                    onChange={(e) => setCatatanAktif(e.target.value)}
                  />
                  <button className="bg-[#6363c2] text-white p-2 rounded text-xs block">
                    Masukkan
                  </button>
                </form>
              </div>
              <button
                onClick={() => {
                  mutateCatatan();
                }}
                className="block bg-biru text-white w-[140px] mx-auto mt-2 rounded hover:shadow-md h-[33px] text-xs font-semibold"
              >
                {pendingCatatan ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Simpan perubahan"
                )}
              </button>
            </>
          )}

          <div className="flex justify-end lg:hidden">
            <button
              onClick={() => setShowChat(true)}
              className="bg-[#6363c2] text-white text-xs px-4 py-3 flex items-center rounded-md mt-3 mr-3"
            >
              <img src={chat} alt="" className="" />
            </button>
          </div>
        </div>
      ) : (
        <p className="lg:w-1/2">No data</p>
      )}
    </>
  );
}

export default BimbinganDosen;
