import React, { useEffect, useState } from "react";
import { onSnapshot, query, doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db } from "../../firebase";

import hero from "../../assets/hero3.png";
import logo from "../../assets/logo.png";
import jadwal from "../../assets/icon_clock_big.png";
import capaian from "../../assets/icon_pantau_capaian.png";
import catatan from "../../assets/icon_catatan.png";
import collab from "../../assets/collab.png";
import acuan from "../../assets/acuan.png";
import waktu from "../../assets/aturwaktu.png";
import sinergi from "../../assets/sinergi.png";

function Home() {
  const [kuponLeft, setKuponLeft] = useState("");
  const colRef = doc(db, "kupon", "beempro");
  useEffect(() => {
    const unsubscribe = onSnapshot(query(colRef), (snapshot) => {
      const left = snapshot.data().limit - snapshot.data().usage;
      setKuponLeft(left);
    });

    return () => {
      unsubscribe();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="relative overflow-x-hidden">
      <div className="flex items-center absolute top-0 left-0 w-1/2 z-10 p-4 lg:p-8">
        <img src={logo} alt="logo" className="w-[25px] lg:w-[30px] mr-3" />
        <h1 className="font-bold text-[#3b69fd]">beembingan.com</h1>
      </div>
      <div className="lg:grid grid-cols-2 items-center w-[100vw] h-[90vh]">
        <div className="relative w-full h-full bg-[#fafafa]">
          <div className="lg:w-[500px] w-[98vw] px-8 py-12 lg:p-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img src={hero} alt="" className="w-full h-auto lg:hidden mb-8" />
            <h1 className="text-xl lg:text-3xl text-[#3b69fd] font-semibold">
              Proses Bimbingan Lebih{" "}
              <span className="text-[#9245ff]">Efisien</span>,
            </h1>
            <h1 className="text-xl lg:text-2xl text-[#3b69fd] font-semibold">
              Lebih Terstruktur.
            </h1>
            <p className="mt-4 lg:text-lg text-slate-500 mb-8">
              Membantu proses bimbingan, memudahkan pekerjaan bagi mahasiswa dan
              dosen.
            </p>
            <Link
              to="/register"
              target="_blank"
              className="bg-biru text-white px-8 py-3 font-semibold rounded mt-8 hover:shadow-xl"
            >
              Daftar Sekarang
            </Link>
          </div>
        </div>
        <div className="linear-bg-custom h-full relative hidden lg:block">
          <div className="w-[600px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img src={hero} alt="" className="w-full h-auto" />
          </div>
        </div>
      </div>

      <div className="lg:flex justify-between items-center max-w-[1100px] mx-auto py-10 lg:py-28">
        <div className="text-center lg:w-1/3 px-10 lg:px-0">
          <img src={jadwal} alt="jadwal" className="h-[40px] mx-auto mb-3" />
          <h1 className="mb-2 font-semibold text-[#735bf3] text-lg">
            Jadwal yang Jelas
          </h1>
          <p className="text-[14px] text-slate-500">
            Jadwal bimbingan yang jelas membuat mahasiswa dan dosen sama-sama
            nyaman mengatur waktu bimbingan.
          </p>
        </div>
        <div className="text-center mt-10 lg:mt-0 lg:w-1/3 lg:mx-12  px-10 lg:px-0">
          <img src={capaian} alt="capaian" className="h-[40px] mx-auto mb-3" />
          <h1 className="mb-2 font-semibold text-[#735bf3] text-lg">
            Pantau Pencapaian
          </h1>
          <p className="text-[14px] text-slate-500">
            Lihat sejauh mana capaian tugas akhir. Dosen dapat dengan mudah
            memantau sejauh mana capaian tiap mahasiswa bimbingan.
          </p>
        </div>
        <div className="text-center  lg:w-1/3  px-10 lg:px-0 mt-10 lg:mt-0">
          <img src={catatan} alt="catatan" className="h-[40px] mx-auto mb-3" />
          <h1 className="mb-2 font-semibold text-[#735bf3] text-lg">
            Simpan Catatan
          </h1>
          <p className="text-[14px] text-slate-500">
            Simpan catatan. Berikan poin-poin penting sebagai rujukan, dan
            sebagai acuan untuk memeriksa kembali hasil revisi bimbingan.
          </p>
        </div>
      </div>

      <div className="py-10 lg:py-20 bg-[#fafafa]">
        <h1 className="text-center text-xl text-slate-600 font-semibold mb-5 lg:mb-10">
          Kolaboratif
        </h1>
        <div className="max-w-[800px] mx-auto lg:flex justify-between items-center px-10 lg:px-0">
          <div className="lg:w-3/4 lg:mr-16">
            <p className="text-[#6c63ff] mb-2 font-semibold text-center">
              Tidak menghilangkan semangat bimbingan tatap muka
            </p>
            <p className="text-slate-500">
              Tetap berkolaborasi saat jarak tidak memungkinkan untuk bimbingan
              secara langsung.
            </p>
          </div>
          <img src={collab} alt="collab" className="lg:w-[40%] rounded-2xl" />
        </div>
      </div>

      <div className="py-10 lg:py-20">
        <h1 className="text-center text-xl text-slate-600 font-semibold mb-5 lg:mb-10">
          Acuan Bersama
        </h1>
        <div className="max-w-[800px] mx-auto lg:flex justify-between items-center px-10 lg:px-0">
          <img
            src={acuan}
            alt="collab"
            className="lg:w-[40%] rounded-2xl lg:mr-16"
          />
          <div className="lg:w-3/4 text-center">
            <p className="text-[#6c63ff] mb-2 font-semibold text-center">
              Pantau bersama pengerjaan bimbingan
            </p>
            <p className="text-slate-500 mb-2">
              Untuk teman-teman dosen pembimbing, pantau semua pengerjaan
              bimbingan dari tiap mahasiswa.
            </p>
            <p className="text-slate-500">
              Untuk teman-teman mahasiswa bimbingan, dapat dengan mudah melihat
              catatan yang diberikan pembimbing.
            </p>
          </div>
        </div>
      </div>

      <div className="py-10 lg:py-20 bg-[#fafafa]">
        <h1 className="text-center text-xl text-slate-600 font-semibold  mb-5 lg:mb-10">
          Atur Waktu
        </h1>
        <div className="max-w-[800px] mx-auto lg:flex justify-between items-center px-10 lg:px-0">
          <div className="lg:w-3/4 lg:mr-16 text-center lg:text-left">
            <p className="text-[#6c63ff] mb-2 font-semibold">
              Sesuaikan jadwal bimbingan
            </p>
            <p className="text-slate-500 mb-2">
              Tidak perlu repot menginformasikan jadwal bimbingan. Dengan
              platform beembingan.com, dosen pembimbing dapat membuat
              jadwal-jadwal bimbingan untuk kemudian diajukan oleh mahasiswa.
            </p>
            <p className="text-slate-500">
              Dosen tinggal menyetujui, dan bimbingan tatap muka dapat
              dilaksanakan.
            </p>
          </div>
          <img src={waktu} alt="collab" className="lg:w-[40%] rounded-2xl" />
        </div>
      </div>

      <div className="py-10 lg:py-20 bg-[#fafafa]">
        <h1 className="text-center text-xl text-slate-600 font-semibold mb-5 lg:mb-10 px-5">
          Sinergi. Dalam Jaringan & Luar Jaringan
        </h1>
        <div className="max-w-[800px] mx-auto lg:flex justify-between items-center px-10 lg:px-0">
          <img
            src={sinergi}
            alt="collab"
            className="lg:w-[40%] rounded-2xl lg:mr-16"
          />
          <div className="lg:w-3/4 text-center">
            <p className="text-[#6c63ff] mb-2 font-semibold">
              Ajukan Pertanyaan & Klarifikasi Kapan Saja
            </p>
            <p className="text-slate-500 mb-2">
              Ketika ada suatu pertanyaan yang mendesak untuk ditanyakan, dapat
              langsung berkomunikasi menggunakan fitur chat di dalam platform.
            </p>
            <p className="text-slate-500">
              Tidak harus menggunakan aplikasi pesan lainnya, yang berpotensi
              tidak terbaca karena tenggelam oleh pesan-pesan personal.
            </p>
          </div>
        </div>
      </div>

      <div className="py-10 lg:py-20">
        <h1 className="text-center text-lg text-[#3b69fd] font-semibold mb-5 lg:mb-10">
          Pertanyaan yang Sering Diajukan :
        </h1>
        <div className="max-w-[800px] mx-auto px-5 lg:px-0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="font-semibold"
            >
              Platform beembingan.com diperuntukkan bagi siapa?
            </AccordionSummary>
            <AccordionDetails className="text-slate-500">
              Platform beembingan.com diperuntukkan bagi dosen dan mahasiswa.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="font-semibold"
            >
              Apakah mahasiswa dapat menggunakan jika dosen tidak menggunakan?
            </AccordionSummary>
            <AccordionDetails className="text-slate-500">
              Platform masih dapat digunakan, namun tentu tanpa dosen pembimbing
              maka menjadi tidak begitu bermanfaat.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="font-semibold"
            >
              Apakah platform beembingan.com dapat mengganti bimbingan tatap
              muka?
            </AccordionSummary>
            <AccordionDetails className="text-slate-500">
              Bisa iya dan bisa tidak, tergantung kesepakatan dan situasi antara
              dosen dan mahasiswa. Akan tetapi, pada dasarnya platform
              bimbingan.com dikembangkan untuk melengkapi proses bimbingan tatap
              muka, bukan untuk menggantikannya.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="font-semibold"
            >
              Apakah platform terhubung dengan sistem akademik kampus?
            </AccordionSummary>
            <AccordionDetails className="text-slate-500">
              Tidak. Beembingan.com adalah platform independen yang bebas
              digunakan oleh siapa saja, berada di luar sistem akademik kampus.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="font-semibold"
            >
              Apakah bisa bimbingan dengan dosen dari luar kampus?
            </AccordionSummary>
            <AccordionDetails className="text-slate-500">
              Ya. Platform beembingan.com memungkinkan mahasiswa untuk melakukan
              bimbingan dengan dosen dari luar kampus.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div className="pt-0 py-20 px-5 lg:px-0">
        <h1 className="text-center text-lg text-slate-600 font-semibold mb-5">
          Harga
        </h1>
        {/* <div className="max-w-[800px] mx-auto grid grid-cols-2 gap-4">
          <div className="border-2 border-slate-300 rounded-xl p-8">
            <p className="text-center text-[#3b69fd] font-semibold mb-4">
              Dosen
            </p>
            <p>Gratis sepenuhnya, tanpa biaya apapun</p>
          </div>
          <div className="border-2 border-slate-300 rounded-xl p-8">
            <p className="text-center text-[#3b69fd] font-semibold mb-4">
              Mahasiswa
            </p>
            <p className="text-center font-semibold mb-2">
              Rp. 50.0000 / 6 bulan
            </p>
            <p className="text-center">Coba gratis selama 30 hari</p>
          </div>
        </div> */}
        <div className="max-w-[1100px] mx-auto text-center text-slate-500">
          <p className="font-semibold">Untuk Dosen:</p>
          <p className="mb-4">Gratis sepenuhnya, tanpa biaya apapun.</p>
          <p className="font-semibold">Untuk Mahasiswa:</p>
          <p className="">
            Kami menyediakan 100 kupon promosi bagi 100 pendaftar pertama.
          </p>
          <p>Berlaku untuk 6 bulan pemakaian.</p>
          <p>Kode kupon dapat dilihat di dalam platform setelah mendaftar.</p>
          <p className="mt-5">
            Kupon tersisa:{" "}
            <span className="font-semibold text-slate-600">{kuponLeft}</span>
          </p>
        </div>
        <Link
          to="/register"
          target="_blank"
          className="bg-biru text-white px-8 py-3 font-semibold rounded mt-8 hover:shadow-xl mx-auto w-[200px] block"
        >
          Daftar Sekarang
        </Link>
      </div>
      <div className="py-10 bg-[#3e3e56]">
        <div className=" max-w-[1100px] mx-auto text-white text-center">
          <p>Kontak</p>
          <p>Email: beembingan@protonmail.com</p>
          <p>Telpon/WhatsApp: 088227904566 (Pungki)</p>
        </div>
        <div className="max-w-[1100px] mx-auto text-white border-t border-gray-100 mt-5 py-5">
          <p className="text-center">Copyright © 2024 Beembingan.com</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
