import React, { useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import spinner from "../../../assets/spinner.gif";

function Popup({
  showPop,
  clickOutside,
  closePop,
  state,
  sesi,
  setListener,
  clearSesi,
  clearState,
  refetch,
}) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        clickOutside && clickOutside();
      }
    };
    // console.log("render");
    document.addEventListener("click", handleClickOutside, true);
    setListener(true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      setListener(false);
    };
  }, [clickOutside, setListener]);
  const jadwalDoc = doc(
    db,
    process.env.REACT_APP_USER,
    auth.currentUser.uid,
    "jadwal",
    state.currentId
  );

  const fetchAktivasi = () => {
    return updateDoc(jadwalDoc, {
      active: !sesi.active,
    });
  };
  const { isPending: loadingAktivasi, mutate } = useMutation({
    mutationFn: fetchAktivasi,
    onSuccess: () => {
      closePop();
      clearSesi();
      clearState();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const fetchHapus = () => {
    return deleteDoc(jadwalDoc);
  };
  const { isPending: loadingHapus, mutate: hapus } = useMutation({
    mutationFn: fetchHapus,
    onSuccess: () => {
      closePop();
      clearSesi();
      clearState();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div
      ref={ref}
      className={`${
        showPop ? "block" : "hidden"
      } absolute lg:top-11 right-0 lg:right-5 w-[220px] bg-white py-4 shadow-lg rounded-md border border-gray-200`}
    >
      {state.isEditing ? (
        <>
          <div
            onClick={mutate}
            className="flex items-center px-6 py-2 hover:bg-gray-200 cursor-pointer justify-between"
          >
            <p className=" text-blax text-xs font-semibold">
              {sesi.active
                ? "Nonaktifkan sesi terpilih"
                : "Aktifkan sesi terpilih"}
            </p>
            {loadingAktivasi && (
              <img src={spinner} alt="" className="w-[15px] h-[15px]" />
            )}
          </div>
          <div
            onClick={hapus}
            className="flex items-center px-6 py-2 hover:bg-gray-200 cursor-pointer justify-between"
          >
            <p className=" text-blax text-xs font-semibold">
              Hapus sesi terpilih
            </p>
            {loadingHapus && (
              <img src={spinner} alt="" className="w-[15px] h-[15px]" />
            )}
          </div>
        </>
      ) : (
        <p className="px-6 py-2 text-center text-gray-400">
          Tidak ada sesi terpilih
        </p>
      )}
    </div>
  );
}

export default Popup;
