import React, { useState } from "react";
import dayjs from "dayjs";

import useJadwalDosen from "./useJadwalDosen";
import spinner from "../../../assets/spinner.gif";

function JadwalDosen() {
  const {
    isLoading,
    data,
    dataMhs,
    pendingTolak,
    mutateTolak,
    pendingSetujui,
    mutateSetujui,
    isPending,
    mutate,
  } = useJadwalDosen();
  const [idTolak, setIdTolak] = useState("");
  const [idSetujui, setIdSetujui] = useState("");

  const renderDiajukan = (type) => {
    if (!data || data.empty) {
      return;
    }

    const filteredData = data.docs.filter((item) => {
      if (type === "disetujui") {
        return item.data().accepted === true;
      } else {
        return item.data().accepted === false;
      }
    });

    dataMhs &&
      filteredData.forEach((item, idx) => {
        dataMhs.forEach((mhs) => {
          if (item.data().mahasiswaid === mhs.id) {
            //beware, this is inserting prop outside data() function
            //so use item.<prop> when calling, not item.data().<prop>
            filteredData[idx].nama = mhs.nama;
          }
        });
      });
    return filteredData.map((doc) => {
      return (
        <div
          key={doc.id}
          className="grid grid-cols-2 gap-2 items-center p-4 shadow mb-4 rounded-lg bg-gray-100"
        >
          <div>
            <p>{doc.nama}</p>
            <p>
              {doc.data().label}, {dayjs(doc.data().date).format("D MMMM")}
            </p>

            <p className="font-semibold">
              {doc.data().from} - {doc.data().to}
            </p>
          </div>

          <div className="flex justify-end">
            {doc.data().accepted ? (
              <button
                onClick={() => {
                  setIdTolak(doc.id);

                  mutate(doc.id);
                }}
                className="border border-rose-400 w-[70px] h-[30px] rounded text-xs text-rose-400 font-semibold hover:shadow-lg mr-4"
              >
                {isPending && idTolak === doc.id ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Hapus"
                )}
              </button>
            ) : (
              <button
                onClick={() => {
                  setIdTolak(doc.id);
                  mutateTolak({
                    appointmentId: doc.id,
                    mahasiswaid: doc.data().mahasiswaid,
                  });
                }}
                className="border border-rose-400 w-[70px] h-[30px] rounded text-xs text-rose-400 font-semibold hover:shadow-lg mr-4"
              >
                {pendingTolak && idTolak === doc.id ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Tolak"
                )}
              </button>
            )}
            {!doc.data().accepted && (
              <button
                onClick={() => {
                  setIdSetujui(doc.id);
                  mutateSetujui({
                    appointmentId: doc.id,
                    mahasiswaid: doc.data().mahasiswaid,
                  });
                }}
                className="border border-[#3b69fd] w-[70px] h-[30px] rounded text-xs text-white font-semibold bg-[#3b69fd] hover:shadow-lg"
              >
                {pendingSetujui && idSetujui === doc.id ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="setuju"
                  />
                ) : (
                  "Setujui"
                )}
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  if (dataMhs && dataMhs.length < 1) {
    return (
      <div>
        <h1 className="ml-4 lg:ml-0 mt-2 mb-4 text-[16px] text-slate-600">
          Jadwal
        </h1>
        <p>Tidak ada data</p>
      </div>
    );
  }
  return (
    <div>
      <h1 className="ml-4 lg:ml-0 mt-2 mb-4 text-[16px] text-slate-600">
        Jadwal
      </h1>
      {isLoading && <p>Loading...</p>}
      <div className="lg:grid grid-cols-2 gap-3">
        <div className="bg-white p-5 rounded-2xl">
          <p className="font-semibold text-slate-500 mb-2">
            Diajukan Mahasiswa:
          </p>
          {renderDiajukan("menunggu")}
        </div>
        <div className="bg-white p-5 rounded-2xl">
          <p className="font-semibold text-slate-500 mb-2">
            Sudah Anda Setujui :
          </p>
          {renderDiajukan("disetujui")}
        </div>
      </div>
    </div>
  );
}

export default JadwalDosen;
