import React, { useContext } from "react";
import UserContext from "../../../context/userContext";
import BerandaMhs from "./BerandaMhs";
import BerandaDosen from "./BerandaDosen";

function Beranda() {
  const { user } = useContext(UserContext);

  return (
    <div>
      <h1 className="ml-4 lg:ml-0 mt-2 mb-4 text-[16px] text-slate-600">
        Beranda
      </h1>
      {user.role === "mahasiswa" && <BerandaMhs />}
      {user.role === "dosen" && <BerandaDosen />}
    </div>
  );
}

export default Beranda;
