import React, { useState, useContext } from "react";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import UserContext from "./context/userContext";
import useAccess from "./routes/mainpage/useAccess";

import logo from "./assets/logo.png";
import warrning from "./assets/icon_warning.png";
import spinner from "./assets/spinner.gif";

function SetProfile() {
  const { setUser, setAksesValid } = useContext(UserContext);
  const { user_access } = useAccess();
  const [selectedRole, setSelectedRole] = useState("");
  const options = [
    {
      value: "mahasiswa",
      label: "Mahasiswa",
    },
    {
      value: "dosen",
      label: "Dosen",
    },
  ];
  const navigate = useNavigate();
  const fetchUser = async () => {
    const referrer = localStorage.getItem("referrer");
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        role: selectedRole,
        referrer: referrer || null,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isPending, mutate } = useMutation({
    mutationFn: fetchUser,
    onSuccess: async () => {
      const docSnap = await getDoc(
        doc(db, process.env.REACT_APP_USER, auth.currentUser.uid)
      );
      if (docSnap.data().role === "mahasiswa") {
        setAksesValid(user_access(docSnap.data()));
      }
      setUser(docSnap.data());
      localStorage.removeItem("referrer");
      navigate("/app/pengaturan");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleSetRole = (val) => {
    setSelectedRole(val);
  };
  return (
    <div className="">
      <h1 className="p-4 font-bold text-gradient text-sans">beembingan.com</h1>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center w-[330px] lg:w-auto">
        <img src={logo} alt="" className="w-[50px] mx-auto mb-5" />
        <p className="text-slate-600 mb-8">
          Silakan pilih kategori profil Anda
        </p>
        <div className="flex items-center justify-between">
          <Select
            options={options}
            placeholder="Saya adalah"
            className="text-left w-[220px] lg:w-[280px] mr-2"
            onChange={(e) => handleSetRole(e.value)}
          />
          <button
            onClick={() => {
              if (!selectedRole) return;
              mutate();
            }}
            className="bg-biru text-white font-semibold w-[100px] h-[36px] rounded  block hover:shadow-lg text-[14px]"
          >
            {isPending ? (
              <img className="w-5 mx-auto my-0" src={spinner} alt="" />
            ) : (
              "Kirim"
            )}
          </button>
        </div>
        <div className=" mt-8">
          <img src={warrning} alt="" className="w-[30px] mx-auto" />
          <p className=" text-gray-500 text-[14px]">
            Kategori tidak dapat diubah lagi di kemudian hari
          </p>
        </div>
      </div>
    </div>
  );
}

export default SetProfile;
