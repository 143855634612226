import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../../assets/logo.png";
import beranda from "../../assets/icon_home.png";
import kalender from "../../assets/icon_kalender.png";
import pengajuan from "../../assets/icon_pengajuan.png";
import pensil from "../../assets/icon_pensil.png";
import arsip from "../../assets/icon_arsip.png";

function Navbar() {
  //   when adding nev menu, add items to the array
  const menu = ["Beranda", "Jadwal", "Pengajuan", "Bimbingan"];
  const icon = [beranda, kalender, pengajuan, pensil, arsip];
  const renderNav = () => {
    return menu.map((item, index) => {
      return (
        <div key={item} className="flex items-center p-1">
          <NavLink
            to={`/app/${item.toLocaleLowerCase()}`}
            className="w-full flex px-4 py-2 rounded-md items-center border border-white  text-gray-500 hover:text-[#3685f7] font-semibold"
          >
            <div className="">
              <img
                src={icon[index]}
                alt="icon-beranda"
                className="mr-4 w-[20px]"
              />
            </div>
            <h2 className=" ">{item}</h2>
          </NavLink>
        </div>
      );
    });
  };

  return (
    <>
      <div className="bg-white w-[245px] h-full py-5 border-r border-gray-100 hidden lg:block z-20">
        <img
          src={logo}
          alt="beembingan-logo"
          className="mx-auto my-0 w-[40px]"
        />
        <h1 className="text-center text font-bold text-gradient text-sans">
          beembingan.com
        </h1>
        <div className="mt-8 px-4 w-[245px] text-center">{renderNav()}</div>
      </div>
      {/* Mobile Menu */}
      <div className="flex justify-around fixed bottom-0 p-3 bg-white w-full border-t border-black-100 lg:hidden z-10">
        <NavLink to="/app/beranda" className="">
          <div className="icon-menu rounded-full w-[35px] h-[35px] mx-auto my-0 flex items-center justify-center">
            <img src={beranda} alt="beranda" className="w-[20px] " />
          </div>
          <p className="text-xs">Beranda</p>
        </NavLink>
        <NavLink to="/app/jadwal" className="">
          <div className="icon-menu rounded-full w-[35px] h-[35px] mx-auto my-0 flex items-center justify-center">
            <img src={kalender} alt="beranda" className="w-[20px] " />
          </div>
          <p className="text-xs">Jadwal</p>
        </NavLink>
        <NavLink to="/app/pengajuan" className="">
          <div className="icon-menu rounded-full w-[35px] h-[35px] mx-auto my-0 flex items-center justify-center">
            <img src={pengajuan} alt="beranda" className="w-[20px] " />
          </div>
          <p className="text-xs">Pengajuan</p>
        </NavLink>
        <NavLink to="/app/bimbingan" className="">
          <div className="icon-menu rounded-full w-[35px] h-[35px] mx-auto my-0 flex items-center justify-center">
            <img src={pensil} alt="beranda" className="w-[20px] " />
          </div>
          <p className="text-xs">Bimbingan</p>
        </NavLink>
        {/* <NavLink to="/app/arsip" className="">
          <div className="icon-menu rounded-full w-[35px] h-[35px] mx-auto my-0 flex items-center justify-center">
            <img src={arsip} alt="beranda" className="w-[20px] " />
          </div>
          <p className="text-xs">Arsip</p>
        </NavLink> */}
      </div>
    </>
  );
}

export default Navbar;
