import { useState, useContext } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
// import { doc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

import { auth, storage } from "../../../firebase";
import UserContext from "../../../context/userContext";

function useBimbinganMhs() {
  const { user, selectedDosenId, setSelectedDosenId } = useContext(UserContext);
  const [judul, setJudul] = useState("");
  const [doing, setDoing] = useState("");
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isEditingJudul, setIsEditingJudul] = useState(false);
  const [isEditingDoing, setIsEditingDoing] = useState(false);

  // const fetchBimbingan = () => {
  //   return getDoc(
  //     doc(db, process.env.REACT_APP_BIMBINGAN, auth.currentUser.uid)
  //   );
  // };
  // const { data, refetch } = useQuery({
  //   queryKey: ["mahasiswa-bimbingan"],
  //   queryFn: fetchBimbingan,
  // });

  const getBimbinganDetails = async () => {
    if (user.role === "dosen") return [];
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    if (da.dosenDetails.length > 0 && !selectedDosenId.value) {
      setSelectedDosenId({
        value: da.dosenDetails[0].dosenid,
        label: da.dosenDetails[0].dosenName,
        photoUrl: da.data.photoUrl,
      });
    }
    if (selectedDosenId.value) {
      //do chat reads here
    }
    return da;
  };
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["data-bimbingan"],
    queryFn: getBimbinganDetails,
  });

  const updateBimbingan = async (field) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        field,
        bimbinganid: auth.currentUser.uid,
        value: field === "judul" ? judul : doing,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isPending, mutate } = useMutation({
    mutationFn: updateBimbingan,
    onSuccess: () => {
      setIsEditingDoing(false);
      setIsEditingJudul(false);
      refetch();
    },
  });

  const updateDocs = async (dosenUtamaId) => {
    if (!file && !fileUrl) {
      return;
    }
    if (file && fileUrl) {
      toast.warning("Pilih antara dokumen atau tautan", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (file) {
      const storageRef = ref(
        storage,
        `${auth.currentUser.uid}/${dosenUtamaId}/${file.name}`
      );
      const fileForm = new FormData();
      fileForm.append("file", file);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);
      const token = await auth.currentUser.getIdToken();
      const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bimbinganid: auth.currentUser.uid,
          field: "docsUrl",
          downloadUrl,
          name: file.name,
          mahasiswaName: user.nama,
          uploader: user.nama,
        }),
      });
      const da = await uu.json();
      return da;
    }
    if (fileUrl) {
      const token = await auth.currentUser.getIdToken();
      const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bimbinganid: auth.currentUser.uid,
          field: "docsUrl",
          downloadUrl: fileUrl,
          name: "URL Dokumen",
          mahasiswaName: user.nama,
        }),
      });
      const da = await uu.json();
      return da;
    }
  };
  const { isPending: pendingUpload, mutate: mutateUpload } = useMutation({
    mutationFn: updateDocs,
    onSuccess: () => {
      setFileUrl("");
      setFile("");
      refetch();
    },
  });

  return {
    data,
    mutate,
    isPending,
    judul,
    setJudul,
    doing,
    setDoing,
    isEditingJudul,
    setIsEditingJudul,
    isEditingDoing,
    setIsEditingDoing,
    file,
    setFile,
    pendingUpload,
    mutateUpload,
    isLoading,
    fileUrl,
    setFileUrl,
  };
}

export default useBimbinganMhs;
