import React from "react";
import Select from "react-select";
import dayjs from "dayjs";
// dayjs.extend(LocalizedFormat);
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useJadwal from "./useJadwal";
import Popup from "./Popup";
import dot from "../../../assets/icon_dot.png";
import loading from "../../../assets/spinner.gif";
import plus from "../../../assets/icon_plus.png";
import close from "../../../assets/icon_close.png";

function Jadwal() {
  const {
    options,
    docListener,
    setDocListener,
    showPop,
    setShowPop,
    state,
    setState,
    sesi,
    setSesi,
    clearSesi,
    clearState,
    handleEditing,
    data,
    refetch,
    isPending,
    mutate,
    disabledReg,
    disabledKhusus,
    buttonAddStyle,
  } = useJadwal();

  const renderJadwal = (type) => {
    const filtered = data.filter((item) => item.type === type);

    const sorted = () => {
      if (type === "reguler") {
        return filtered.sort((a, b) => a.hari - b.hari);
      }
      if (type === "khusus") {
        return filtered.sort((a, b) =>
          dayjs(a.date, "DD/MM/YYYY").isAfter(dayjs(b.date, "DD/MM/YYYY"))
            ? 1
            : -1
        );
      }
    };
    const ds = sorted();
    return ds.map((item) => {
      return (
        <div key={item.id}>
          <div
            onClick={() => handleEditing(item, type)}
            className={`${
              state.currentId === item.id && state.isEditing
                ? "border-[#8cc3f6] border-2"
                : "border border-gray-300"
            }  rounded p-3 cursor-pointer`}
          >
            <p
              className={`${
                item.active ? "text-blue-400" : "text-gray-400"
              } font-semibold  mb-1`}
            >
              {options[item.hari].label}
            </p>
            {/* <p>{item.date && item.date}</p> */}
            <p
              className={`mb-1 ${
                item.active ? "text-black" : "text-gray-400"
              } `}
            >
              {item.date &&
                dayjs(item.date, "DD/MM/YYYY", "id").format("DD MMM YYYY")}
            </p>

            <p
              className={`${
                item.active ? "text-black" : "text-gray-400"
              } font-semibold`}
            >
              {item.from} - {item.to}
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bg-white p-8 md:p-8 lg:p-10 rounded-xl lg:w-2/3">
      <h1 className="font-semibold text-gray-600 mb-4 lg:mb-6">
        Atur Ketersediaan Jadwal
      </h1>
      <div className="lg:flex items-center relative">
        <h1 className="font-semibold text-[#4154f9] mb-2 lg:mb-0 ">
          Sesi Reguler
        </h1>
        <Select
          options={options}
          placeholder="Pilih hari"
          className="w-full lg:w-1/5 lg:mx-4 text-[16px]"
          isDisabled={disabledReg}
          value={{ value: sesi.hari, label: sesi.label }}
          onChange={(e) => {
            setSesi((prev) => ({ ...prev, hari: e.value, label: e.label }));
          }}
        />

        <div className="flex items-center w-full mt-3 lg:mt-0 lg:w-72">
          <DesktopTimePicker
            format="HH:mm"
            disabled={disabledReg}
            className="w-2/3"
            value={dayjs(sesi.from, "HH:mm")}
            onChange={(val) => {
              setSesi((prev) => ({ ...prev, from: val.format("HH:mm") }));
            }}
          />
          <p className="mx-4">sampai</p>
          <DesktopTimePicker
            format="HH:mm"
            disabled={disabledReg}
            className="w-2/3"
            value={dayjs(sesi.to, "HH:mm")}
            onChange={(val) => {
              setSesi((prev) => ({ ...prev, to: val.format("HH:mm") }));
            }}
          />
        </div>
        <div className="flex items-center mt-3 lg:mt-0 justify-end">
          <button
            onClick={mutate}
            disabled={disabledReg || sesi.hari === ""}
            className="text-xs text-white bg-biru hover:bg-[#557cfb] p-2 rounded lg:ml-4 font-semibold shadow-md w-[80px]"
          >
            {isPending && state.editingType === "reguler" ? (
              <img src={loading} className="w-4 mx-auto my-0" alt="spinner" />
            ) : (
              "Simpan"
            )}
          </button>
          <div
            onClick={() => {
              if (docListener) {
                setState((prev) => ({
                  ...prev,
                  isEditing: false,
                  currentId: "0",
                }));
                return;
              }
              if (state.editingType === "khusus") {
                setState((prev) => ({
                  ...prev,
                  isEditing: false,
                  currentId: "0",
                }));
              }
              setState((prev) => ({ ...prev, editingType: "reguler" }));
              // clearSesi();
              // clearState();
              setShowPop((prev) => ({ ...prev, reguler: !showPop.reguler }));
            }}
            className="border border-gray-300 w-[28px] h-[28px] rounded-lg flex justify-center items-center ml-4 cursor-pointer hover:bg-gray-200"
          >
            <img src={dot} alt="" />
          </div>
        </div>

        {showPop.reguler && state.editingType === "reguler" && (
          <Popup
            showPop={showPop.reguler}
            clickOutside={() =>
              setShowPop((prev) => ({ ...prev, reguler: false }))
            }
            closePop={() => setShowPop((prev) => ({ ...prev, reguler: false }))}
            state={state}
            sesi={sesi}
            setListener={setDocListener}
            clearSesi={clearSesi}
            clearState={clearState}
            refetch={refetch}
          />
        )}
      </div>
      <div className="bg-[#f3f7ff] p-5 mt-3 rounded-lg grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data && renderJadwal("reguler")}
        <div
          onClick={() => {
            setState((prev) => ({
              ...prev,
              isEditing: false,
              currentId: "0",
              isAdding:
                state.editingType === "reguler" || !state.editingType
                  ? !state.isAdding
                  : true,
              editingType: "reguler",
            }));
            clearSesi();
          }}
          className={`border border-dashed ${
            buttonAddStyle("reguler")
              ? "border-[#8cc3f6] border-2"
              : "border-gray-300"
          } hover:border-[#8cc3f6] rounded p-2 text-center grid items-center cursor-pointer`}
        >
          <img
            src={buttonAddStyle("reguler") ? close : plus}
            alt="tambah sesi"
            className="w-[25px] mx-auto my-0"
          />
          {buttonAddStyle("reguler") && (
            <>
              <p className="text-gray-500 mt-2">Silakan isi form di atas</p>
            </>
          )}
        </div>
      </div>
      <div className="border-t border-gray-200 mt-6"></div>

      <div className="lg:flex items-center mt-6 relative">
        <h1 className="font-semibold text-[#4154f9] mb-2 lg:mb-0 lg:mr-4">
          Sesi Khusus
        </h1>
        <DesktopDatePicker
          format="DD/MM/YYYY"
          className="text-sm w-full lg:w-1/5 lg:mx-4"
          value={dayjs(sesi.date, "DD/MM/YYYY")}
          disabled={disabledKhusus}
          onChange={(val) => {
            const day = dayjs(val).get("d");
            const renderDay = () => {
              if (day === 0) {
                return 6;
              } else {
                return day - 1;
              }
            };

            setSesi((prev) => ({
              ...prev,
              date: val,
              hari: renderDay(),
              label: options[renderDay()].label,
            }));
          }}
        />
        <div className="flex items-center w-full mt-3 lg:mt-0 lg:w-72 lg:ml-4">
          <DesktopTimePicker
            format="HH:mm"
            disabled={disabledKhusus}
            className="w-2/3"
            value={dayjs(sesi.from_khusus, "HH:mm")}
            onChange={(val) => {
              setSesi((prev) => ({
                ...prev,
                from_khusus: val.format("HH:mm"),
              }));
            }}
          />

          <p className="mx-4">sampai</p>
          <DesktopTimePicker
            disabled={disabledKhusus}
            format="HH:mm"
            className="w-2/3"
            value={dayjs(sesi.to_khusus, "HH:mm")}
            onChange={(val) => {
              setSesi((prev) => ({
                ...prev,
                to_khusus: val.format("HH:mm"),
              }));
            }}
          />
        </div>
        <div className="flex items-center mt-3 lg:mt-0 justify-end">
          <button
            onClick={mutate}
            disabled={disabledKhusus}
            className="text-xs text-white bg-biru hover:bg-[#557cfb] p-2 rounded ml-4 font-semibold shadow-md w-[80px]"
          >
            {isPending && state.editingType === "khusus" ? (
              <img src={loading} className="w-4 mx-auto my-0" alt="spinner" />
            ) : (
              "Simpan"
            )}
          </button>
          <div
            onClick={() => {
              if (docListener) {
                setState((prev) => ({
                  ...prev,
                  isEditing: false,
                  currentId: "0",
                }));
                return;
              }
              if (state.editingType === "reguler") {
                setState((prev) => ({
                  ...prev,
                  isEditing: false,
                  currentId: "0",
                }));
              }
              setState((prev) => ({ ...prev, editingType: "khusus" }));
              // clearSesi();
              // clearState();
              setShowPop((prev) => ({ ...prev, khusus: !showPop.khusus }));
            }}
            className="border border-gray-300 w-[28px] h-[28px] rounded-lg flex justify-center items-center ml-4 cursor-pointer hover:bg-gray-200"
          >
            <img src={dot} alt="" />
          </div>
        </div>

        {showPop.khusus && state.editingType === "khusus" && (
          <Popup
            showPop={showPop.khusus}
            clickOutside={() =>
              setShowPop((prev) => ({ ...prev, khusus: false }))
            }
            closePop={() => setShowPop((prev) => ({ ...prev, khusus: false }))}
            state={state}
            sesi={sesi}
            setListener={setDocListener}
            clearSesi={clearSesi}
            clearState={clearState}
            refetch={refetch}
          />
        )}
      </div>

      <div className="bg-[#f0f7f8] mt-3 p-5 rounded-lg grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data && renderJadwal("khusus")}
        <div
          onClick={() => {
            setState((prev) => ({
              ...prev,
              isEditing: false,
              currentId: "0",
              isAdding:
                state.editingType === "khusus" || !state.editingType
                  ? !state.isAdding
                  : true,
              editingType: "khusus",
            }));
            clearSesi();
          }}
          className={`border border-dashed ${
            buttonAddStyle("khusus")
              ? "border-[#8cc3f6] border-2"
              : "border-gray-300"
          } hover:border-[#8cc3f6] rounded p-2 text-center grid items-center cursor-pointer`}
        >
          <img
            src={buttonAddStyle("khusus") ? close : plus}
            alt="tambah sesi"
            className="w-[25px] mx-auto my-0"
          />
          {buttonAddStyle("khusus") && (
            <>
              <p className="text-gray-500 mt-2">Silakan isi form di atas</p>
            </>
          )}
        </div>
      </div>
      {/* <p className="text-gray-500">
          (Sesi khusus adalah ketersediaan bimbingan pada hari di luar sesi
          reguler)
        </p> */}
    </div>
  );
}

export default Jadwal;
