import React, { useContext } from "react";
import UserContext from "../../../context/userContext";
import PengajuanMhs from "./PengajuanMhs";
import PengajuanDosen from "./PengajuanDosen";

function Pengajuan() {
  const { user } = useContext(UserContext);

  return (
    <>
      {user.role === "dosen" && <PengajuanDosen />}

      {user.role === "mahasiswa" && <PengajuanMhs />}
    </>
  );
}

export default Pengajuan;
