import React, { useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";

// import { Link } from "react-router-dom";

// import mail from "../../assets/icon_mail.png";
import alarm from "../../assets/icon_alarm.png";
import pengajuan from "../../assets/icon_doc_baru.png";

function Notifikasi({ onClickOutside, setListener, notifData }) {
  const img = {
    jadwal: alarm,
    pengajuan,
  };
  const navigate = useNavigate();
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    setListener(true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      setListener(false);
    };
  }, [onClickOutside, setListener]);

  const renderNotif = () => {
    if (notifData.length < 1) {
      return;
    }
    return notifData.map((item) => {
      return (
        <div
          //why not onClick? onCLick will just make it disappear first
          //because the click is outside, see useEffect
          onMouseDown={() => {
            if (!item.isread) {
              updateDoc(
                doc(
                  db,
                  process.env.REACT_APP_USER,
                  auth.currentUser.uid,
                  "notification",
                  item.id
                ),
                {
                  isread: true,
                }
              );
              setTimeout(() => {
                if (window.location.pathname === `/app/${item.type}`) {
                  window.location.reload(true);
                } else {
                  navigate(`/app/${item.type}`);
                }
              }, 300);
            } else {
              if (window.location.pathname === `/app/${item.type}`) {
                window.location.reload(true);
              } else {
                navigate(`/app/${item.type}`);
              }
            }
          }}
          key={item.id}
          className="flex items-center leading-4 border-b border-gray-100 py-4 cursor-pointer px-6 hover:bg-gray-100"
        >
          <img src={img[item.type]} alt="" className="mr-4 w-[18px]" />
          <p className="">{`${item.msg}`}</p>
          {!item.isread && (
            <div className="ml-4">
              <div className="w-[7px] h-[7px] bg-blue-400 rounded-full"></div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div
      ref={ref}
      className="absolute top-12 w-[350px] py-6 right-4 lg:right-40 bg-white border-gray-200 rounded-xl text-gray-500 z-10 shadow-md"
    >
      <div className="flex justify-between w-full border-b border-gray-200 pb-4 px-6">
        <p
          onMouseDown={() => navigate("/app/notifikasi")}
          className="text-[12px] text-blue-400 cursor-pointer hover:underline"
        >
          Lihat semua notifikasi
        </p>
        <p
          onMouseDown={async () => {
            const token = await auth.currentUser.getIdToken();
            await fetch(`${process.env.REACT_APP_API}notifikasi/allread`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });
          }}
          className="text-[12px] text-blue-400 cursor-pointer hover:underline"
        >
          Tandai semua dibaca
        </p>
      </div>
      {notifData && (
        <div className=" max-h-[400px] overflow-y-auto">{renderNotif()}</div>
      )}
    </div>
  );
}

export default Notifikasi;
