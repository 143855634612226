import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import {
  doc,
  addDoc,
  updateDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";

function useJadwal() {
  const options = [
    {
      value: 0,
      label: "Senin",
    },
    {
      value: 1,
      label: "Selasa",
    },
    {
      value: 2,
      label: "Rabu",
    },
    {
      value: 3,
      label: "Kamis",
    },
    {
      value: 4,
      label: "Jumat",
    },
    {
      value: 5,
      label: "Sabtu",
    },
    {
      value: 6,
      label: "Minggu",
    },
  ];
  const [docListener, setDocListener] = useState(false);
  const [showPop, setShowPop] = useState({ reguler: false, khusus: false });
  const [state, setState] = useState({
    isAdding: false,
    isEditing: false,
    currentId: "0",
    editingType: "",
  });

  // console.log(state.editingType);

  const [sesi, setSesi] = useState({
    hari: "",
    date: dayjs(),
    label: "",
    from: "07:00",
    to: "07:00",
    from_khusus: "07:00",
    to_khusus: "07:00",
    active: true,
    type: "",
  });
  const docRef = doc(db, process.env.REACT_APP_USER, auth.currentUser.uid);
  const colRef = collection(docRef, "jadwal");
  const subColRef = collection(
    db,
    process.env.REACT_APP_USER,
    auth.currentUser.uid,
    "jadwal"
  );
  const jadwalDoc = doc(
    db,
    process.env.REACT_APP_USER,
    auth.currentUser.uid,
    "jadwal",
    state.currentId
  );
  // console.log(state);
  // console.log(sesi);
  const disabledReg =
    (state.isAdding && state.editingType === "reguler") ||
    (state.isEditing && state.editingType === "reguler")
      ? false
      : true;
  const disabledKhusus =
    (state.isAdding && state.editingType === "khusus") ||
    (state.isEditing && state.editingType === "khusus")
      ? false
      : true;
  const buttonAddStyle = (type) => {
    return state.isAdding && !state.isEditing && state.editingType === type;
  };
  const clearSesi = () => {
    setSesi({
      hari: "",
      date: dayjs(),
      label: "",
      from: "07:00",
      to: "07:00",
      from_khusus: "07:00",
      to_khusus: "07:00",
      active: false,
      type: "",
    });
  };
  const clearState = (type) => {
    setState({
      isAdding: false,
      isEditing: false,
      currentId: "0",
      editingType: type,
    });
  };

  const handleEditing = (item, type) => {
    setState((prev) => ({
      ...prev,
      editingType: type,
    }));
    if (state.isAdding) {
      setState((prev) => ({
        ...prev,
        isAdding: false,
      }));
    }
    if (!state.currentId || state.currentId !== item.id) {
      setState((prev) => ({ ...prev, isEditing: true, currentId: item.id }));
      setSesi({
        hari: item.hari,
        label: item.label,
        date: type === "reguler" ? dayjs() : item.date,
        from: type === "reguler" ? item.from : sesi.from,
        to: type === "reguler" ? item.to : sesi.to,
        from_khusus: type === "khusus" ? item.from : sesi.from_khusus,
        to_khusus: type === "khusus" ? item.to : sesi.to_khusus,
        active: item.active,
        type: item.type,
      });
    } else {
      setState((prev) => ({ ...prev, isEditing: false, currentId: "0" }));
      clearSesi();
    }
  };
  const fetchJadwal = () => {
    return getDocs(subColRef);
  };
  const { data, refetch } = useQuery({
    queryKey: ["fetch-jadwal"],
    queryFn: fetchJadwal,
    select: (item) => {
      const jadwal = item.docs.map((d) => ({ id: d.id, ...d.data() }));
      return jadwal;
    },
  });
  // console.log(dayjs(sesi.date).format("DD/MM/YYYY"));
  const fetchSaveSesi = () => {
    const setKhususHari = () => {
      const day = dayjs(sesi.date).get("d");
      const renderDay = () => {
        if (day === 0) {
          return 6;
        } else {
          return day - 1;
        }
      };
      const d = renderDay();
      return d;
    };

    if (state.isEditing) {
      return updateDoc(jadwalDoc, {
        ...sesi,
        from: state.editingType === "reguler" ? sesi.from : sesi.from_khusus,
        to: state.editingType === "reguler" ? sesi.to : sesi.to_khusus,
        date:
          state.editingType === "reguler"
            ? ""
            : dayjs(sesi.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
      });
    } else {
      return addDoc(colRef, {
        ...sesi,
        hari: state.editingType === "reguler" ? sesi.hari : setKhususHari(),
        label:
          state.editingType === "reguler"
            ? sesi.label
            : options[setKhususHari()].label,
        active: true,
        type: state.editingType === "reguler" ? "reguler" : "khusus",
        from: state.editingType === "reguler" ? sesi.from : sesi.from_khusus,
        to: state.editingType === "reguler" ? sesi.to : sesi.to_khusus,
        date:
          state.editingType === "reguler"
            ? ""
            : dayjs(sesi.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
      });
    }
  };
  const { isPending, mutate, error } = useMutation({
    mutationFn: fetchSaveSesi,
    onSuccess: () => {
      clearSesi();
      clearState("");
      refetch();
    },
    onError: (err) => {
      toast.error("Error", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
    },
  });

  return {
    options,
    docListener,
    setDocListener,
    showPop,
    setShowPop,
    state,
    setState,
    sesi,
    setSesi,
    clearSesi,
    clearState,
    handleEditing,
    data,
    refetch,
    isPending,
    mutate,
    disabledReg,
    disabledKhusus,
    buttonAddStyle,
    error,
  };
}
export default useJadwal;
