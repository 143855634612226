import { useContext, useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import UserContext from "../../../context/userContext";

import { auth, db, storage } from "../../../firebase";

function useBimbinganDosen() {
  const { selectedMahasiswaId, setSelectedMahasiswaId, setAllBimbingan, user } =
    useContext(UserContext);
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isSuntingCapaian, setIsSuntingCapaian] = useState(false);
  const [catatan, setCatatan] = useState([]);

  const [templateList, setTemplateList] = useState([
    {
      id: 0,
      title: "Pendahuluan / Latar Belakang Masalah",
      active: true,
    },
    {
      id: 1,
      title: "Tinjauan Pustaka",
      active: false,
    },
    {
      id: 2,
      title: "Metode Penelitian",
      active: false,
    },
    {
      id: 3,
      title: "Hasil Penelitian dan Pembahasan",
      active: false,
    },
    {
      id: 4,
      title: "Kesimpulan dan Saran",
      active: false,
    },
  ]);

  const fetchBimbingan = (mhsId) => {
    return getDoc(doc(db, process.env.REACT_APP_BIMBINGAN, mhsId));
  };
  const {
    isPending,
    data: dataMhs,
    mutate,
  } = useMutation({
    mutationFn: fetchBimbingan,
    onSuccess: (datamhs) => {
      if (datamhs.data().capaian) {
        setTemplateList(datamhs.data().capaian);
      }
      if (datamhs.data().catatan) {
        setCatatan(datamhs.data().catatan);
      } else {
        setCatatan([]);
      }
    },
  });

  const fetchMhsList = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    setAllBimbingan(da);

    if (da.length > 0 && !selectedMahasiswaId.value) {
      setSelectedMahasiswaId({ value: da[0].id, label: da[0].nama });
      mutate(da[0].id);
    }
    if (selectedMahasiswaId.value) {
      mutate(selectedMahasiswaId.value);
    }
    return da;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["mahasiswa-bimbingan"],
    queryFn: fetchMhsList,
  });

  const updateBimbingan = async (value) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        field: "capaian",
        bimbinganid: selectedMahasiswaId.value,
        value,
      }),
    });
    const da = await uu.json();
    return da;
  };

  const { isPending: pendingUpdate, mutate: mutateUpdate } = useMutation({
    mutationFn: updateBimbingan,
    onSuccess: () => {
      setIsSuntingCapaian(false);
      mutate(selectedMahasiswaId.value);
    },
  });

  const updateCatatan = async () => {
    if (!selectedMahasiswaId) {
      return;
    }
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        field: "catatan",
        bimbinganid: selectedMahasiswaId.value,
        value: catatan,
      }),
    });
    const da = await uu.json();
    return da;
  };

  const { isPending: pendingCatatan, mutate: mutateCatatan } = useMutation({
    mutationFn: updateCatatan,
    onSuccess: () => {
      mutate(selectedMahasiswaId.value);
    },
  });

  const updateDocs = async () => {
    if (!file && !fileUrl) {
      console.log("ahy");
      return;
    }
    if (file && fileUrl) {
      toast.warning("Pilih antara dokumen atau tautan", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (file) {
      const storageRef = ref(
        storage,
        `${selectedMahasiswaId.value}/${auth.currentUser.uid}/${file.name}`
      );
      const fileForm = new FormData();
      fileForm.append("file", file);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);
      const token = await auth.currentUser.getIdToken();
      const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bimbinganid: selectedMahasiswaId.value,
          field: "docsUrl",
          downloadUrl,
          name: file.name,
          uploader: user.nama,
        }),
      });
      const da = await uu.json();
      return da;
    }
    if (fileUrl) {
      const token = await auth.currentUser.getIdToken();
      const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bimbinganid: selectedMahasiswaId.value,
          field: "docsUrl",
          downloadUrl: fileUrl,
          name: "URL Dokumen",
          mahasiswaName: user.nama,
        }),
      });
      const da = await uu.json();
      return da;
    }
  };
  const { isPending: pendingUpload, mutate: mutateUpload } = useMutation({
    mutationFn: updateDocs,
    onSuccess: () => {
      setFileUrl("");
      setFile("");
      mutate(selectedMahasiswaId.value);
    },
  });

  return {
    data,
    mutate,
    dataMhs,
    isPending,
    selectedMahasiswaId,
    setSelectedMahasiswaId,
    pendingUpdate,
    mutateUpdate,
    isSuntingCapaian,
    setIsSuntingCapaian,
    templateList,
    setTemplateList,
    isLoading,
    catatan,
    setCatatan,
    pendingCatatan,
    mutateCatatan,
    file,
    setFile,
    pendingUpload,
    mutateUpload,
    fileUrl,
    setFileUrl,
  };
}

export default useBimbinganDosen;
