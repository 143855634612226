import React, { useContext, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";

import { sendEmailVerification } from "firebase/auth";
import "dayjs/locale/id";

import { Outlet } from "react-router-dom";
import UserContext from "../../context/userContext";
import { auth } from "../../firebase";

import Navbar from "./Navbar";
import Topmenu from "./Topmenu";

function Beranda() {
  const { showChat } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="id">
      <div
        className={`${
          showChat ? "h-0 overflow-hidden" : "h-screen "
        } w-screen  bg-[#fafafa] flex text-[14px]`}
      >
        <Navbar />
        <div className="w-full">
          <Topmenu />

          <div className={`pt-12 lg:px-10 pb-[100px] lg:pb-10 bg-[#fafafa]`}>
            {auth.currentUser && !auth.currentUser.emailVerified && (
              <div className="bg-indigo-500 flex justify-center text-sm text-white p-2 text-center w-full z-30 items-center mb-4">
                <p>Silakan verifikasi email Anda</p>
                <button
                  onClick={async () => {
                    setLoading(true);
                    // const continueUrl = {
                    //   url: "https://beembingan.com/app/beranda"
                    // }
                    await sendEmailVerification(auth.currentUser);
                    toast.success(
                      "Link verifikasi telah dikirim ke email Anda",
                      {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                    setLoading(false);
                  }}
                  className="border border-white px-2 ml-4 rounded text-xs py-1 hover:shadow-xl w-[130px]"
                >
                  {loading ? "Mengirim..." : "Kirim ulang verifikasi"}
                </button>
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default Beranda;
