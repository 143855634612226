import { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../../../firebase";
import UserContext from "../../../context/userContext";

function usePengaturan() {
  const { user, setUser } = useContext(UserContext);

  const [editProfil, setEditProfil] = useState(false);
  const [idbimbingan, setIdBimbingan] = useState("");
  const [idTaken, setIdTaken] = useState(false);
  const [editIdBimbingan, setEditIdBimbingan] = useState(false);
  const [profil, setProfil] = useState({
    nama: "",
    legalNumber: "",
    universitas: "",
    fakultas: "",
  });
  const docRef = doc(db, process.env.REACT_APP_USER, auth.currentUser.uid);

  const mutateUser = () => {
    return updateDoc(docRef, profil);
  };
  const { isPending, mutate } = useMutation({
    mutationFn: mutateUser,
    onSuccess: () => {
      setEditProfil(false);
      setUser((prev) => ({ ...prev, ...profil }));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const fetchIdBimbingan = async () => {
    const docSnap = await getDoc(
      doc(db, process.env.REACT_APP_BIMBINGANID, idbimbingan)
    );
    if (docSnap.exists()) {
      setIdTaken(true);
      throw new Error("ID sudah terpakai!");
    }

    const newid = idbimbingan.replaceAll(" ", "");
    await updateDoc(docRef, {
      idbimbingan: newid,
    });
    return setDoc(doc(db, process.env.REACT_APP_BIMBINGANID, newid), {
      dosenid: auth.currentUser.uid,
    });
  };
  const { isPending: pendingBimbingan, mutate: mutateBimbingan } = useMutation({
    mutationFn: fetchIdBimbingan,
    onMutate: () => {
      setIdTaken(false);
    },
    onSuccess: () => {
      setUser((prev) => ({ ...prev, idbimbingan: idbimbingan }));
      setEditIdBimbingan(false);
      setIdBimbingan("");
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const updateIdBimbingan = async () => {
    const docSnap = await getDoc(
      doc(db, process.env.REACT_APP_BIMBINGANID, idbimbingan)
    );
    if (docSnap.exists()) {
      setIdTaken(true);
      throw new Error("ID sudah terpakai!");
    }
    return deleteDoc(
      doc(db, process.env.REACT_APP_BIMBINGANID, user.idbimbingan)
    );
  };

  const { isPending: pendingUpdate, mutate: updateBimbingan } = useMutation({
    mutationFn: updateIdBimbingan,
    onSuccess: () => {
      mutateBimbingan();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const storageRef = ref(
    storage,
    `${auth.currentUser.uid}/${auth.currentUser.uid}`
  );
  // console.log(error && error.code);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const acceptedType = ["image/png", "image/jpeg", "image/webp"];
    if (!acceptedType.includes(file.type)) {
      return;
    }
    const imgForm = new FormData();
    imgForm.append("file", event.target.files[0]);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await updateDoc(doc(db, process.env.REACT_APP_USER, auth.currentUser.uid), {
      photoUrl: downloadURL,
    });
    setUser((prev) => ({ ...prev, photoUrl: downloadURL }));
  };

  return {
    editProfil,
    setEditProfil,
    profil,
    setProfil,
    isPending,
    mutate,
    handleUpload,
    pendingBimbingan,
    mutateBimbingan,
    idbimbingan,
    setIdBimbingan,
    idTaken,
    editIdBimbingan,
    setEditIdBimbingan,
    updateBimbingan,
    pendingUpdate,
  };
}

export default usePengaturan;
