import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../firebase";
import { style } from "../style";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";
import logo from "../../assets/logo.png";
import header from "../../assets/header.svg";

function Register() {
  const [registerType, setRegisterType] = useState("SIGNUP");
  const [error, setError] = useState("");

  useEffect(() => {
    //automatically logged user in if it's still autheticated
    onAuthStateChanged(auth, (_user) => {
      if (_user) {
        navigate("/app/beranda");
      }
    });
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  return (
    <div className="block lg:flex h-screen">
      <div className="w-full lg:w-1/2 relative h-screen">
        <div className={`w-full text-center px-10 ${style.centerized}`}>
          {error && (
            <div className={style.errorDiv}>
              <p className="text-red-400 text-sm">{error}</p>
            </div>
          )}
          <img
            className="mx-auto my-0 w-[40px] lg:w-[70px]"
            src={logo}
            alt="logo"
          />

          <h2 className="font text-xl text-gray-500 mb-2 mt-2 lg:mb-8 lg:mt-8">
            Selamat Datang
          </h2>
          <div className="bg-gray-100  md:w-72 mx-auto my-0 p-2 flex rounded-2xl mb-4">
            <button
              onClick={() => {
                setError("");
                setRegisterType("LOGIN");
              }}
              className={`${
                registerType === "LOGIN"
                  ? "bg-white text-[#426af3]"
                  : "text-gray-400"
              }  ${style.btnRegister}`}
            >
              Masuk
            </button>
            <button
              onClick={() => {
                setError("");
                setRegisterType("SIGNUP");
              }}
              className={`${
                registerType === "SIGNUP"
                  ? "bg-white text-[#426af3]"
                  : "text-gray-400"
              }  ${style.btnRegister}`}
            >
              Daftar
            </button>
          </div>
          {registerType === "SIGNUP" && (
            <h1 className="mb-2 text-gray-500">Isi form untuk Mendaftar</h1>
          )}
          {registerType === "LOGIN" ? (
            <SigninForm setError={setError} />
          ) : (
            <SignupForm setError={setError} />
          )}
        </div>
      </div>

      <div className="hidden lg:block linear-bg-custom w-1/2 relative">
        <h1 className="text-white text-right pt-4 pr-7 font-bold text-xl">
          beembingan.com
        </h1>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img src={header} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Register;
