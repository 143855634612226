import { useQuery } from "@tanstack/react-query";
import { auth } from "../../../firebase";

function useBerandaDosen() {
  const fetchMhsList = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    return da;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["mahasiswa-bimbingan"],
    queryFn: fetchMhsList,
  });

  return {
    isLoading,
    data,
  };
}

export default useBerandaDosen;
