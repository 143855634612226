import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

import { auth } from "../../firebase";

function useSignup(setError) {
  const { referrer } = useParams();

  const options = [
    {
      value: "mahasiswa",
      label: "Mahasiswa",
    },
    {
      value: "dosen",
      label: "Dosen",
    },
  ];
  const [user, setUserData] = useState({ email: "", password: "" });

  const handleSetUser = (val, prop) => {
    setUserData((prev) => ({ ...prev, [prop]: val }));
  };

  const fetchSignup = async () => {
    // const continueUrl = {
    //   url: "https://beembingan.com/app/beranda"
    // }
    await createUserWithEmailAndPassword(auth, user.email, user.password);
    return sendEmailVerification(auth.currentUser);

    // return createUserWithEmailAndPassword(auth, user.email, user.password);
  };

  const { isPending, mutate } = useMutation({
    mutationFn: fetchSignup,
    onSuccess: () => {
      if (referrer) {
        localStorage.setItem("referrer", referrer);
      }
    },
    onError: (error) => {
      console.log(error);
      switch (error.code) {
        case "auth/invalid-email":
          setError("Email tidak sah");
          break;
        case "auth/email-already-in-use":
          setError("Email sudah digunakan");
          break;
        case "auth/weak-password":
          setError("Password minimal 6 karakter");
          break;
        default:
          setError(error.code);
      }
    },
  });

  const handleSignup = (e) => {
    e.preventDefault();
    if (!user.email || !user.password) {
      return;
    }
    mutate();
  };
  return {
    handleSignup,
    handleSetUser,
    isPending,
    options,
  };
}
export default useSignup;
