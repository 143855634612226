import { useContext, useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";

import { auth, db } from "../../../firebase";
import UserContext from "../../../context/userContext";

function useChat() {
  const { user, selectedDosenId, setSelectedDosenId, chatData, setChatData } =
    useContext(UserContext);
  const unsubChat = useRef();
  const [lastId, setLastId] = useState("");
  const [lastView, setLastView] = useState("");

  const writeChat = async (message) => {
    const token = await auth.currentUser.getIdToken();
    const reqBody = {
      message,
      dosenid: selectedDosenId.value,
    };
    const uu = await fetch(`${process.env.REACT_APP_API}chat/write`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqBody),
    });
    const da = await uu.json();

    return da;
  };

  const { mutate: mutateChat } = useMutation({
    mutationFn: writeChat,
  });

  return {
    user,
    auth,
    selectedDosenId,
    setSelectedDosenId,
    db,
    mutateChat,
    lastId,
    lastView,
    chatData,
    setChatData,
    setLastView,
    setLastId,
    unsubChat,
  };
}

export default useChat;
