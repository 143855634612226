import React from "react";
// import UserContext from "../../../context/userContext";
import { toast } from "react-toastify";
import usePengajuanMhs from "./usePengajuanMhs";
import spinner from "../../../assets/spinner.gif";
import box from "../../../assets/icon_box.png";
import close_gray from "../../../assets/icon_close_gray.png";

function PengajuanMhs() {
  //   const { user, setUser } = useContext(UserContext);
  const {
    isPending,
    dataDosen,
    setIdBimbingan,
    handleSearchBimbingan,
    pendingAjukan,
    mutateAjukan,
    showResult,
    dataPeng,
    isLoading,
    pendingDelete,
    mutateDelete,
    idtobeDeleted,
    activeSearch,
    setActiveSearch,
    idbimbingan,
    setShowResult,
    idPendamping,
    setIdPendamping,
    // testTime,
  } = usePengajuanMhs();

  const renderColor = (status) => {
    if (status === "menunggu") {
      return "text-amber-500";
    }
    if (status === "diterima") {
      return "text-blue-500";
    }
    if (status === "ditolak") {
      return "text-gray-500";
    }
  };

  const renderPengajuanList = (type) => {
    // console.log(dataPeng);
    if (dataPeng.length < 1) {
      return (
        <div className="mt-2 text-center py-4 lg:py-8 bg-gray-50">
          <img src={box} alt="" className="w-[40px] mx-auto my-0 mb-3" />
          <p className="text-gray-400 font-semibold">Tidak ada data</p>
        </div>
      );
    } else {
      const typeFilter = dataPeng.filter((item) => item.type === type);
      return typeFilter.map((item) => {
        return (
          <div
            key={item.legalNumber}
            className="mt-4 shadow rounded-lg p-8 lg:grid lg:grid-cols-6 items-center gap-4 bg-white"
          >
            <div className="lg:flex items-center col-span-2 text-center">
              <img
                src={item.photoUrl || "https://placehold.co/80"}
                alt="dosen"
                className="w-[80px] h-[80px] rounded-full object-cover lg:mr-5 mx-auto my-0 lg:mx-0"
              />

              <p className="font-bold text-slate-500">{item.nama}</p>
            </div>

            <div className="col-span-2 text-center">
              <p className="text-gray-500">{item.fakultas}</p>
              <p className="text-slate-600 font-semibold">{item.universitas}</p>
              <p className="text-blax">NIDN: {item.legalNumber}</p>
            </div>
            <div
              className={`${renderColor(
                item.status
              )} text-center p-3 rounded-full font-semibold`}
            >
              <span className="text-gray-600 font-normal">status : </span>
              {item.status}
            </div>
            {!item.accepted && (
              <button
                onClick={() => {
                  mutateDelete(item.id);
                }}
                className="border block border-rose-400 hover:shadow-md text-rose-400  text-xs font-semibold rounded-md lg:mr-4 w-[100px] h-[38px] mx-auto my-0"
              >
                {pendingDelete && idtobeDeleted === item.id ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Hapus"
                )}
              </button>
            )}
          </div>
        );
      });
    }
  };

  return (
    <div className="max-w-[960px]  mx-auto my-0">
      <h1 className="ml-4 lg:ml-0 mb-4 text-[16px] text-slate-600">
        Pengajuan
      </h1>
      <div className="bg-white p-8 rounded-xl ">
        <h1 className="mb-2">Dosen Utama</h1>
        <form
          onSubmit={(e) => {
            setActiveSearch("utama");
            handleSearchBimbingan(e);
          }}
          className="flex items-center"
        >
          <div className="relative w-full">
            <input
              onChange={(e) => {
                if (!e.target.value) {
                  setShowResult(false);
                }
                setIdBimbingan(e.target.value);
              }}
              value={idbimbingan}
              type="text"
              placeholder="Cari ID bimbingan"
              className="bg-gray-100 rounded-lg p-3 w-full text-[16px]"
            />
            <img
              src={close_gray}
              alt="close search"
              className="absolute right-3 top-3 cursor-pointer"
              onClick={() => {
                setShowResult(false);
                setIdBimbingan("");
              }}
            />
          </div>

          <button
            type="submit"
            className="bg-biru  w-[100px] h-[35px] text-white ml-5 text-xs font-semibold rounded hover:shadow-md"
          >
            {isPending && activeSearch === "utama" ? (
              <img
                className="w-[14px] mx-auto my-0"
                src={spinner}
                alt="menunggu"
              />
            ) : (
              "Cari"
            )}
          </button>
        </form>

        {dataDosen && showResult && activeSearch === "utama" && (
          <div className="mt-4 shadow rounded-lg p-8 text-center lg:flex items-center justify-between">
            <img
              src={dataDosen.data().photoUrl || "https://placehold.co/80"}
              alt="dosen"
              className="w-[80px] h-[80px] rounded-full object-cover mb-4 lg:mb-0 mx-auto my-0 lg:mx-0"
            />

            <p className="font-bold text-slate-500">{dataDosen.data().nama}</p>
            <div>
              <p className="text-gray-500">{dataDosen.data().fakultas}</p>
              <p className="text-slate-600 font-semibold">
                {dataDosen.data().universitas}
              </p>

              <p className="text-blax mb-4 lg:mb-0">
                NIDN: {dataDosen.data().legalNumber}
              </p>
            </div>
            <div>
              <button
                onClick={() => {
                  if (pendingAjukan) {
                    return;
                  }
                  let exist = false;
                  if (dataPeng.length > 0) {
                    dataPeng.forEach((element) => {
                      if (element.id === dataDosen.id) {
                        toast.warn("Sudah diajukan", {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        exist = true;
                      }
                    });
                  }
                  if (exist) {
                    return;
                  }
                  // console.log(dataPeng);
                  mutateAjukan("utama");
                }}
                className="border border-[#3b69fd] text-[#3b69fd]  w-[100px] h-[35px] ml-5 text-xs font-semibold rounded-md hover:shadow-md"
              >
                {pendingAjukan ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Ajukan"
                )}
              </button>
            </div>
          </div>
        )}
        <div className="mt-5">
          {/* <button onClick={() => testTime()} className="bg-biru text-white p-4">
          test time
        </button> */}
          <h1 className=" text-slate-500">Diajukan</h1>
          {isLoading && <p className="text-gray-300 italic">Loading...</p>}

          {dataPeng && renderPengajuanList("utama")}
        </div>
      </div>

      <div className="bg-white p-8 rounded-xl mt-5">
        <h1 className="mb-2">Dosen Pendamping</h1>
        <form
          onSubmit={(e) => {
            setActiveSearch("pendamping");
            handleSearchBimbingan(e);
          }}
          className="flex items-center"
        >
          <div className="relative w-full">
            <input
              onChange={(e) => {
                if (!e.target.value) {
                  setShowResult(false);
                }
                setIdPendamping(e.target.value);
              }}
              value={idPendamping}
              type="text"
              placeholder="Cari ID bimbingan"
              className="bg-gray-100 rounded-lg p-3 w-full text-[16px]"
            />
            <img
              src={close_gray}
              alt="close search"
              className="absolute right-3 top-3 cursor-pointer"
              onClick={() => {
                setShowResult(false);
                setIdPendamping("");
              }}
            />
          </div>

          <button
            type="submit"
            className="bg-biru  w-[100px] h-[35px] text-white ml-5 text-xs font-semibold rounded hover:shadow-md"
          >
            {isPending && activeSearch === "pendamping" ? (
              <img
                className="w-[14px] mx-auto my-0"
                src={spinner}
                alt="menunggu"
              />
            ) : (
              "Cari"
            )}
          </button>
        </form>

        {dataDosen && showResult && activeSearch === "pendamping" && (
          <div className="mt-4 shadow rounded-lg p-8 text-center lg:flex items-center justify-between">
            <img
              src={dataDosen.data().photoUrl || "https://placehold.co/80"}
              alt="dosen"
              className="w-[80px] h-[80px] rounded-full object-cover mb-4 lg:mb-0 mx-auto my-0 lg:mx-0"
            />

            <p className="font-bold text-slate-500">{dataDosen.data().nama}</p>
            <div>
              <p className="text-gray-500">{dataDosen.data().fakultas}</p>
              <p className="text-slate-600 font-semibold">
                {dataDosen.data().universitas}
              </p>

              <p className="text-blax mb-4 lg:mb-0">
                NIDN: {dataDosen.data().legalNumber}
              </p>
            </div>
            <div>
              <button
                onClick={() => {
                  if (pendingAjukan) {
                    return;
                  }
                  let exist = false;
                  if (dataPeng.length > 0) {
                    dataPeng.forEach((element) => {
                      if (element.id === dataDosen.id) {
                        toast.warn("Sudah diajukan", {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        exist = true;
                      }
                    });
                  }
                  if (exist) {
                    return;
                  }
                  // console.log(dataPeng);
                  mutateAjukan("pendamping");
                }}
                className="border border-[#3b69fd] text-[#3b69fd]  w-[100px] h-[35px] ml-5 text-xs font-semibold rounded-md hover:shadow-md"
              >
                {pendingAjukan ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Ajukan"
                )}
              </button>
            </div>
          </div>
        )}
        <div className="mt-5">
          <h1 className=" text-slate-500">Diajukan</h1>
          {isLoading && <p className="text-gray-300 italic">Loading...</p>}

          {dataPeng && renderPengajuanList("pendamping")}
        </div>
      </div>
    </div>
  );
}

export default PengajuanMhs;
