import React, { useContext, useState } from "react";
import Select from "react-select";
import dayjs from "dayjs";

import UserContext from "../../../context/userContext";
import useJadwalMhs from "./useJadwalMhs";
import spinner from "../../../assets/spinner.gif";

function JadwalMhs() {
  const { selectedDosenId, setSelectedDosenId } = useContext(UserContext);
  const {
    isLoading,
    data,
    dataDosen,
    loadingDosen,
    isPending,
    mutate,
    loadingAppointment,
    dosens,
    dataAppointment,
    pendingDelete,
    mutateDelete,
  } = useJadwalMhs();
  const [idAjukan, setIdAjukan] = useState("");
  const [idDelete, setIdDelete] = useState("");
  const renderJadwalReguler = () => {
    if (!data) return;
    if (data.length < 1) {
      return <p>no data</p>;
    }
    const filteredData = data.filter((item) => item.type === "reguler");

    if (filteredData.length < 1) {
      return <p>Tidak ada jadwal tersedia</p>;
    }
    filteredData.sort((a, b) => a.hari - b.hari);

    return filteredData.map((item) => {
      return (
        <div
          key={item.id}
          className="shadow p-3 mb-4 rounded-md flex items-center justify-between"
        >
          <p>{item.label}</p>
          <p className="text-gray-600">
            {item.from} - {item.to}
          </p>
          <button
            onClick={() => {
              setIdAjukan(item.id);
              mutate({
                from: item.from,
                to: item.to,
                date: item.date,
                label: item.label,
                hari: item.hari,
              });
            }}
            className="bg-biru text-xs text-white font-semibold w-[70px] h-[30px] rounded hover:shadow-lg"
          >
            {isPending && idAjukan === item.id ? (
              <img
                className="w-[14px] mx-auto my-0"
                src={spinner}
                alt="menunggu"
              />
            ) : (
              "Ajukan"
            )}
          </button>
        </div>
      );
    });
  };

  const renderJadwalKhusus = () => {
    if (!data) return;

    if (data.length < 1) {
      return <p>no data</p>;
    }
    const filteredData = data.filter((item) => item.type === "khusus");
    if (filteredData.length < 1) {
      return <p>Tidak ada jadwal khusus</p>;
    }
    filteredData.sort((a, b) => a.date - b.date);
    return filteredData.map((item) => {
      return (
        <div
          key={item.id}
          className="shadow p-3 mb-2 rounded-md flex items-center justify-between"
        >
          <div>
            <p>{item.label}</p>
            <p>{dayjs(item.date, "DD/MM/YYYY", "id").format("DD MMMM YYYY")}</p>
          </div>

          <p className="text-gray-600">
            {item.from_khusus} - {item.to_khusus}
          </p>
          <button
            onClick={() => {
              setIdAjukan(item.id);
              mutate({
                from: item.from_khusus,
                to: item.to_khusus,
                date: item.date,
                label: item.label,
                hari: item.hari,
              });
            }}
            className="bg-biru text-xs text-white font-semibold w-[70px] h-[30px] rounded hover:shadow-lg"
          >
            {isPending && idAjukan === item.id ? (
              <img
                className="w-[14px] mx-auto my-0"
                src={spinner}
                alt="menunggu"
              />
            ) : (
              "Ajukan"
            )}
          </button>
        </div>
      );
    });
  };
  const renderOptions = () => {
    if (!dataDosen) {
      return [];
    }
    const opt = [];
    dataDosen.dosenDetails.forEach((item) => {
      opt.push({
        value: item.dosenid,
        label: item.dosenName,
      });
    });
    return opt;
  };

  const renderDiajukan = (type) => {
    if (!dataAppointment || dataAppointment.empty) {
      return;
    }

    const filteredData = dataAppointment.docs.filter((item) => {
      if (type === "disetujui") {
        return item.data().accepted === true;
      } else {
        return item.data().accepted === false;
      }
    });

    dosens &&
      filteredData.forEach((item, idx) => {
        dosens.forEach((dosen) => {
          if (item.data().dosenid === dosen.dosenid) {
            //beware, this is inserting prop outside data() function
            //so use item.<prop> when calling, not item.data().<prop>
            filteredData[idx].dosenName = dosen.dosenName;
          }
        });
      });
    return filteredData.map((doc) => {
      return (
        <div
          key={doc.id}
          className="grid grid-cols-2 items-center p-4 shadow mb-4 rounded-lg bg-gray-100"
        >
          <div>
            <p>
              <span className="font-semibold text-[#735BF3]">
                {doc.data().label}
              </span>
              , {dayjs(doc.data().date).format("D MMMM")}
            </p>

            <p className="font-semibold">
              {doc.data().from} - {doc.data().to}
            </p>
            <p>{doc.dosenName}</p>
          </div>

          <div className="flex justify-between">
            {doc.data().accepted && (
              <p
                className={`text-xs w-[100px] items-center rounded-full text-center py-1 bg-[#735bf3] text-white`}
              >
                disetujui
              </p>
            )}

            {!doc.data().accepted && (
              <p
                className={`text-xs w-[100px] items-center rounded-full text-center py-1   text-gray-500 ${
                  doc.data().status === "menunggu"
                    ? " bg-blue-100"
                    : " bg-gray-200"
                }`}
              >
                {doc.data().status}
              </p>
            )}
            {(!doc.data().accepted ||
              new Date() > new Date(doc.data().date)) && (
              <button
                onClick={() => {
                  setIdDelete(doc.id);
                  mutateDelete(doc.id);
                }}
                className="border border-rose-400 w-[70px] rounded text-xs text-rose-400 font-semibold hover:shadow-lg"
              >
                {pendingDelete && idDelete === doc.id ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Hapus"
                )}
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  if (dataDosen && dataDosen.length < 1) {
    return (
      <div>
        <h1 className="ml-4 lg:ml-0 mt-2 mb-4 text-[16px] text-slate-600">
          Jadwal
        </h1>
        <p>Tidak ada data</p>
      </div>
    );
  }

  return (
    <div className="">
      <h1 className="ml-4 lg:ml-0 mt-2 mb-4 text-[16px] text-slate-600">
        Jadwal
      </h1>
      <div className="lg:grid grid-cols-2 gap-3">
        <div className="bg-white p-5 rounded-2xl">
          <p className="font-semibold text-slate-500 mb-2">Jadwal Tersedia :</p>
          <div className="">
            <Select
              options={renderOptions()}
              placeholder="Pilih dosen"
              className=" text-[16px] mb-4"
              onChange={(e) => {
                setSelectedDosenId({
                  value: e.value,
                  label: e.label,
                });
              }}
              value={selectedDosenId}
            />
          </div>
          {isLoading || (loadingDosen && <p>Loading...</p>)}

          <p className="mb-2 text-xs font-semibold">Jadwal Reguler:</p>
          {selectedDosenId.value && renderJadwalReguler()}
          <p className="mb-2 mt-8 text-xs font-semibold">Jadwal Khusus:</p>
          {selectedDosenId.value && renderJadwalKhusus()}
        </div>
        <div className="bg-white p-5 rounded-2xl">
          <p className="font-semibold text-slate-500 mb-2">
            Semua Jadwal yang Diajukan :
          </p>
          <p>{loadingAppointment && "...loading"}</p>
          <p className="mb-2">Disetujui:</p>

          {dataAppointment && renderDiajukan("disetujui")}
          <p className="mb-2">Menunggu atau Ditolak:</p>
          {dataAppointment && renderDiajukan("menunggu")}
        </div>
      </div>
    </div>
  );
}

export default JadwalMhs;
