import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Home from "./routes/home/Home";

import Register from "./routes/login/Register";
import PrivateRoute from "./PrivateRoute";
import Mainpage from "./routes/mainpage/Mainpage";
import PasswordReset from "./routes/passwordReset/PasswordReset";
import NotFound from "./routes/notfound/NotFound";
import Beranda from "./routes/mainpage/beranda/Beranda";
import Jadwal from "./routes/mainpage/jadwal/Jadwal";
import Pengajuan from "./routes/mainpage/pengajuan/Pengajuan";
import Bimbingan from "./routes/mainpage/bimbingan/Bimbingan";
import Arsip from "./routes/mainpage/arsip/Arsip";
import Pengaturan from "./routes/mainpage/pengaturan/Pengaturan";
import Reward from "./routes/mainpage/reward/Reward";
import SemuaNotifikasi from "./routes/mainpage/notifikasi/SemuaNotifikasi";
import { UserProvider } from "./context/userContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="register" element={<Register />} />
            <Route path="register/:referrer" element={<Register />} />
            <Route path="passwordreset" element={<PasswordReset />} />

            <Route element={<PrivateRoute />}>
              <Route path="/app" element={<Mainpage />}>
                <Route index element={<Navigate to="/app/beranda" />} />
                <Route path="/app/beranda" element={<Beranda />} />
                <Route path="/app/jadwal" element={<Jadwal />} />
                <Route path="/app/pengajuan" element={<Pengajuan />} />
                <Route path="/app/bimbingan" element={<Bimbingan />} />
                <Route
                  path="/app/bimbingan/:mahasiswaid"
                  element={<Bimbingan />}
                />
                <Route path="/app/arsip" element={<Arsip />} />
                <Route path="/app/pengaturan" element={<Pengaturan />} />
                <Route path="/app/reward" element={<Reward />} />
                <Route path="/app/notifikasi" element={<SemuaNotifikasi />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </UserProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
