import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/userContext";

import useBerandaDosen from "./useBerandaDosen";

function BerandaDosen() {
  const { isLoading, data } = useBerandaDosen();
  const { setSelectedMahasiswaId } = useContext(UserContext);
  const navigate = useNavigate();

  const renderBimbingan = () => {
    if (!data) return;
    if (data.length < 1) {
      return;
    }
    return data.map((item) => {
      return (
        <div
          key={item.id}
          className="shadow p-4 mb-4 bg-gray-50 rounded-xl lg:grid grid-cols-3 items-center"
        >
          <div className="lg:flex items-center lg:justify-start mb-4 lg:mb-0 text-center lg:text-left">
            <img
              src={item.photoUrl || "https://placehold.co/70"}
              alt=""
              className="w-[70px] h-[70px] rounded-full lg:mr-8 mx-auto lg:mx-0 mb-2 lg:mb-0 object-cover"
            />
            <div>
              <p className="text-[#735bf3] font-semibold">{item.nama}</p>
              <p>{item.fakultas}</p>
            </div>
          </div>
          <div className="text-center hidden lg:block">
            <p>{item.universitas}</p>
            <p>NIM: {item.legalNumber}</p>
          </div>
          <div className="flex lg:justify-end justify-center mt-4 lg:mt-0">
            <button
              onClick={() => {
                setSelectedMahasiswaId({ value: item.id, label: item.nama });
                navigate(`/app/bimbingan/`);
              }}
              className="border border-[#3b69fd] px-2 py-2 rounded-md text-[#3b69fd] hover:shadow-md text-xs"
            >
              Lihat Bimbingan
            </button>
          </div>
        </div>
      );
    });
  };
  //   console.log(data);
  if (isLoading) {
    return <p className="lg:w-1/2">Loading...</p>;
  }

  return (
    <div className="bg-white p-8 max-w-[1100px] mx-auto rounded-xl">
      <h1 className="mb-4">Mahasiswa Bimbingan</h1>
      {renderBimbingan()}
    </div>
  );
}

export default BerandaDosen;
