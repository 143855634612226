import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import useAccess from "../routes/mainpage/useAccess";
import { auth, db } from "../firebase";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [aksesValid, setAksesValid] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [allBimbingan, setAllBimbingan] = useState([]);
  const [selectedMahasiswaId, setSelectedMahasiswaId] = useState({
    value: "",
    label: "",
  });
  const [selectedDosenId, setSelectedDosenId] = useState({
    value: "",
    label: "",
  });
  const { user_access } = useAccess();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (_user) => {
      setLoading(true);
      if (_user) {
        auth.currentUser.getIdTokenResult(true);
        setIsSignedIn(true);
        const docSnap = await getDoc(
          doc(db, process.env.REACT_APP_USER, _user.uid)
        );
        if (docSnap.exists()) {
          if (docSnap.data().role === "mahasiswa") {
            setAksesValid(user_access(docSnap.data()));
          }
          setUser(docSnap.data());
        }
        // if()
        setLoading(false);
      } else {
        setUser(null);
        setIsSignedIn(false);
        setLoading(false);
      }
    });

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  const param = {
    user,
    setUser,
    aksesValid,
    setAksesValid,
    selectedMahasiswaId,
    setSelectedMahasiswaId,
    selectedDosenId,
    setSelectedDosenId,
    chatData,
    setChatData,
    showChat,
    setShowChat,
    allBimbingan,
    setAllBimbingan,
    isSignedIn,
    loading,
  };
  return <UserContext.Provider value={param}>{children}</UserContext.Provider>;
}

export default UserContext;
