import { useContext, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getDocs, collection, query, where } from "firebase/firestore";
import dayjs from "dayjs";

import { auth, db } from "../../../firebase";
import UserContext from "../../../context/userContext";

function useJadwalMhs() {
  const { selectedDosenId, setSelectedDosenId, user } = useContext(UserContext);
  const [dosens, setDosens] = useState([]);

  const getDosenDetails = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    setDosens(da.dosenDetails);
    if (da.dosenDetails.length > 0 && !selectedDosenId.value) {
      setSelectedDosenId({
        value: da.dosenDetails[0].dosenid,
        label: da.dosenDetails[0].dosenName,
        photoUrl: da.data.photoUrl,
      });
    }
    if (selectedDosenId.value) {
      //do chat reads here
    }
    return da;
  };

  const { isLoading: loadingDosen, data: dataDosen } = useQuery({
    queryKey: ["data-bimbingan"],
    queryFn: getDosenDetails,
  });

  const getJadwalDosen = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}jadwal/dosen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dosenid: selectedDosenId.value,
      }),
    });
    const da = await uu.json();
    // console.log(da);
    return da;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["jadwal-dosen", selectedDosenId.value],
    queryFn: getJadwalDosen,
    select: (data) => {
      return data.sort((a, b) => a.type - b.type);
    },
    enabled: selectedDosenId.value ? true : false,
  });

  const {
    isLoading: loadingAppointment,
    data: dataAppointment,
    refetch,
  } = useQuery({
    queryKey: ["appointment"],
    queryFn: () => {
      const colRef = collection(db, process.env.REACT_APP_APPOINTMENT);
      const q = query(colRef, where("mahasiswaid", "==", auth.currentUser.uid));
      return getDocs(q);
    },
  });

  const ajukanJadwal = async (arg) => {
    const { date, label, from, to, hari, type } = arg;
    const today = dayjs().day() - 1;
    const jadwalMinutesFrom = `${arg.from[3]}${arg.from[4]}`;
    const jadwalHourFrom = `${arg.from[0]}${arg.from[1]}`;

    const dayCountToAppointment = () => {
      const minusSevenMinus = 7 - today;
      if (today < hari) {
        return hari - today;
      }
      if (today > hari) {
        return minusSevenMinus + hari;
      }
      if (today === hari) {
        if (dayjs().hour() === dayjs(jadwalHourFrom, "HH").hour()) {
          return minusSevenMinus + hari;
        }
        if (dayjs().hour() > dayjs(jadwalHourFrom, "HH").hour()) {
          return minusSevenMinus + hari;
        }
        if (dayjs().hour() < dayjs(jadwalHourFrom, "HH").hour()) {
          if (dayjs(jadwalHourFrom, "HH").hour() - dayjs().hour() > 1) {
            return 0;
          }
          if (dayjs(jadwalHourFrom, "HH").hour() - dayjs().hour() === 1) {
            if (dayjs().minute() <= dayjs(jadwalMinutesFrom, "mm").minute()) {
              return 0;
            }
            if (dayjs().minute() > dayjs(jadwalMinutesFrom, "mm").minute()) {
              console.log("iam here");
              return minusSevenMinus + hari;
            }
          }
        }
      }
    };
    function addDays(days) {
      const dateCopy = new Date();
      dateCopy.setDate(new Date().getDate() + days);
      return dateCopy;
    }
    const getDate = addDays(dayCountToAppointment());

    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(
      `${process.env.REACT_APP_API}jadwal/create_appointment`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          date: type === "khusus" ? date : getDate,
          label,
          from,
          to,
          hari,
          nama: user.nama,
          dosenid: selectedDosenId.value,
        }),
      }
    );
    const da = await uu.json();
    return da;
  };

  const { isPending, mutate } = useMutation({
    mutationFn: ajukanJadwal,
    onSuccess: () => {
      refetch();
    },
  });

  const deleteAppointment = async (appointmentId) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}jadwal/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointmentId,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isPending: pendingDelete, mutate: mutateDelete } = useMutation({
    mutationFn: deleteAppointment,
    onSuccess: () => {
      refetch();
    },
  });

  return {
    isLoading,
    data,
    dataDosen,
    loadingDosen,
    isPending,
    mutate,
    loadingAppointment,
    dataAppointment,
    dosens,
    pendingDelete,
    mutateDelete,
  };
}

export default useJadwalMhs;
