import React, { useEffect, useState, useRef, useContext } from "react";
import Select from "react-select";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import UserContext from "../../../context/userContext";
import useBimbinganMhs from "./useBimbinganMhs";
import useChat from "./useChat";

// dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  months: [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
});

function Chat() {
  const { showChat, setShowChat } = useContext(UserContext);

  const { data } = useBimbinganMhs();

  const {
    user,
    auth,
    selectedDosenId,
    setSelectedDosenId,
    db,
    mutateChat,
    lastId,
    lastView,
    chatData,
    setChatData,
    setLastView,
    setLastId,
    unsubChat,
  } = useChat();
  const [message, setMessage] = useState("");

  const msgEndRef = useRef();
  const msgEndRefMobile = useRef();
  const chatDivRef = useRef();
  const [prevHeight, setPrevHeight] = useState(0);

  useEffect(() => {
    // console.log(chatDivRef.current.scrollHeight);
    if (chatDivRef.current) {
      chatDivRef.current.scrollTo(
        0,
        chatDivRef.current.scrollHeight - prevHeight
      );
      setPrevHeight(chatDivRef.current.scrollHeight);
    }
    //eslint-disable-next-line
  }, [chatData, showChat]);

  const fetchNextPage = async (e) => {
    if (!lastView) {
      return;
    }
    const ref = collection(
      db,
      process.env.REACT_APP_CHAT,
      auth.currentUser.uid,
      selectedDosenId.value
    );

    const next = query(
      ref,
      orderBy("created", "desc"),
      startAfter(lastView),
      limit(20)
    );
    const documentSnapshots = await getDocs(next);
    const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    const flatten = [];
    documentSnapshots.docs.forEach((item) => {
      flatten.push({
        ...item.data(),
        id: item.id,
      });
    });
    // console.log(e.target.scrollHeight, prevHeight);
    // console.log(e.target.scrollHeight - prevHeight - e.target.clientHeight);
    const reversed = flatten.reverse();
    if (reversed.length > 0) {
      setChatData((prev) => [...reversed, ...prev]);
      setLastView(lastDoc);
    }
  };

  useEffect(() => {
    let isMounted = true;
    async function init() {
      if (!selectedDosenId.value) {
        return;
      }
      const ref = collection(
        db,
        process.env.REACT_APP_CHAT,
        auth.currentUser.uid,
        selectedDosenId.value
      );

      const initQuery = query(ref, orderBy("created", "desc"), limit(19));
      const documentSnapshots = await getDocs(initQuery);
      const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      const flatten = [];
      if (documentSnapshots.empty) {
        setLastId("");
        setChatData([]);
      } else {
        documentSnapshots.docs.forEach((item) => {
          flatten.push({
            ...item.data(),
            id: item.id,
          });
        });
        const reversed = flatten.reverse();
        setLastId(reversed[reversed.length - 1].id);
        setChatData(reversed);
        setLastView(lastDoc);
      }

      if (isMounted) {
        let querySchema = query(ref, orderBy("created", "desc"), limit(1));
        const unsubscribe = onSnapshot(querySchema, (snapshot) => {
          if (snapshot.empty) {
            return;
          }
          let chats = [];
          snapshot.docs.forEach((doc) => {
            chats.push({ ...doc.data(), id: doc.id });
          });
          if (
            chats[0].senderId === auth.currentUser.uid ||
            chatDivRef.current.scrollHeight - chatDivRef.current.scrollTop ===
              chatDivRef.current.clientHeight
          ) {
            setLastId(chats[0].id);
          }
          // console.log(reversed);
          if (flatten.length < 1) {
            setChatData((prev) => [...prev, chats[0]]);
          } else {
            if (chats[0].id !== flatten[flatten.length - 1].id) {
              setChatData((prev) => [...prev, chats[0]]);
            }
          }
        });
        unsubChat.current = unsubscribe;
      }
    }

    init();

    return () => {
      isMounted = false;
      unsubChat.current && unsubChat.current();
    };

    //eslint-disable-next-line
  }, [selectedDosenId.value]);

  const scrollToBottom = () => {
    msgEndRef.current.scrollIntoView({ behavior: "instant" });
  };

  useEffect(scrollToBottom, [lastId, selectedDosenId.value]);

  const scrollToBottomMobile = () => {
    if (!showChat) return;
    msgEndRefMobile.current.scrollIntoView({ behavior: "instant" });
  };

  useEffect(scrollToBottomMobile, [showChat]);

  const renderOptions = () => {
    if (!data) {
      return [];
    }
    const opt = [];
    data.dosenDetails.forEach((item) => {
      opt.push({
        value: item.dosenid,
        label: item.dosenName,
      });
    });
    return opt;
  };

  const renderChat = () => {
    if (chatData.length < 1) {
      return;
    }
    return chatData.map((item) => {
      // console.log(item.created.toDate().toISOString());
      if (auth.currentUser.uid === item.senderId) {
        return (
          <div key={item.id} className="flex justify-end">
            <div className="w-4/5 mb-4">
              <p className="bg-[#735BF3] text-white rounded-t-3xl rounded-bl-3xl px-4 py-3 leading-4">
                {item.message}
              </p>
              <p className="text-xs text-gray-400 text-right">
                {dayjs(item.created.toDate().toISOString()).format(
                  "D MMMM, HH:mm"
                )}
              </p>
            </div>
            {/* <img
              src={item.photoUrl || "https://placehold.co/50"}
              alt="user"
              className="rounded-full"
            /> */}
          </div>
        );
      } else {
        return (
          <div key={item.id} className="">
            {/* <img
              src={item.photoUrl || "https://placehold.co/50"}
              alt="user"
              className="rounded-full"
            /> */}
            <div className="w-4/5 mb-4">
              <p className="bg-gray-100 text-gray-500 rounded-t-3xl rounded-br-3xl px-4 py-3 leading-4">
                {item.message}
              </p>
              <p className="text-xs text-gray-400 text-right">
                {dayjs(item.created.toDate().toISOString()).format(
                  "D MMMM, HH:mm"
                )}
              </p>
            </div>
          </div>
        );
      }
    });
  };

  const renderMobileChat = () => {
    if (!showChat) return;
    return (
      <div
        className={`h-[100svh] fixed top-0 left-0 z-30  w-[100vw] bg-white lg:hidden `}
      >
        {data && (
          <div className="p-5 border-b border-gray-200">
            <Select
              options={renderOptions()}
              placeholder="Pilih dosen"
              className=" text-[16px] "
              onChange={(e) => {
                setSelectedDosenId({
                  value: e.value,
                  label: e.label,
                });
              }}
              value={selectedDosenId}
            />
          </div>
        )}
        <div className="absolute left-0 bottom-0 w-full p-2 rounded-b-xl border-t bg-white border-gray-200">
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            rows="3"
            placeholder="Tulis pesan"
            className="border border-gray-200 rounded-xl w-full p-2"
          />
          <div className="flex justify-between pb-2 mt-1">
            <div className="">
              <button
                onClick={() => setShowChat(false)}
                className="bg-white px-4 py-2 text-xs font-semibold rounded-md border border-gray-200 shadow-md"
              >
                Tutup
              </button>
            </div>
            <button
              onClick={() => {
                if (!message) return;
                setMessage("");
                mutateChat(message);
              }}
              className="bg-biru text-white w-[100px] py-2 rounded-md text-xs font-semibold hover:shadow-lg"
            >
              Kirim
            </button>
          </div>
        </div>
        <div
          ref={chatDivRef}
          onScroll={(e) => {
            if (e.target.scrollTop === 0) {
              fetchNextPage(e);
            }
          }}
          className={`px-8 pt-5 ${
            user.role === "mahasiswa" ? "h-[60svh]" : "h-[75svh]"
          }  overflow-y-auto`}
        >
          {renderChat()}
          <div ref={msgEndRef}></div>
          <div ref={msgEndRefMobile}></div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* ---------------------------------------Mobile chat---------------------------------- */}
      {renderMobileChat()}
      {/* ---------------------------------------Mobile chat---------------------------------- */}
      <div
        className={`bg-white lg:ml-4 ml-0 lg:w-1/2 rounded-3xl mt-5 lg:mt-0 hidden lg:block ${
          user.role === "mahasiswa" ? "h-[700px]" : "h-[630px]"
        }  relative`}
      >
        {data && (
          <div className="p-5 pb-2 border-b border-gray-200">
            <Select
              options={renderOptions()}
              placeholder="Pilih dosen"
              className=" text-[16px] "
              onChange={(e) => {
                setSelectedDosenId({
                  value: e.value,
                  label: e.label,
                });
              }}
              value={selectedDosenId}
            />
          </div>
        )}
        <div className="absolute left-0 bottom-0 w-full p-5 border-t bg-white border-gray-200">
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            rows="3"
            placeholder="Tulis pesan"
            className="border border-gray-200 rounded-xl w-full p-4 text-[16px]"
          />
          <div className="flex justify-end">
            <button
              onClick={() => {
                if (!message) return;
                setMessage("");
                mutateChat(message);
              }}
              className="bg-biru text-white w-[100px] py-2 rounded-md text-xs font-semibold hover:shadow-lg"
            >
              Kirim
            </button>
          </div>
        </div>
        <div
          ref={chatDivRef}
          onScroll={(e) => {
            if (e.target.scrollTop === 0) {
              fetchNextPage(e);
            }
          }}
          className={`px-8 pt-5 ${
            user.role === "mahasiswa" ? "h-[440px]" : "h-[440px]"
          }  overflow-y-auto`}
        >
          {renderChat()}
          <div ref={msgEndRef}></div>
        </div>
      </div>
    </>
  );
}

export default Chat;
