import React from "react";
import { style } from "../style";

function NotFound() {
  return (
    <div className={`${style.centerized} text-center text-slate-500`}>
      <p className="text-3xl font-bold">404</p>
      <p className="text-xl">Halaman tidak ditemukan</p>
    </div>
  );
}

export default NotFound;
