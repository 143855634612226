import { useContext } from "react";

import { useQuery, useMutation } from "@tanstack/react-query";
import { getDocs, collection, query, where } from "firebase/firestore";
import UserContext from "../../../context/userContext";

import { auth, db } from "../../../firebase";

function useJadwalDosen() {
  const { user } = useContext(UserContext);

  const fetchMhsList = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    return da;
  };

  const { data: dataMhs } = useQuery({
    queryKey: ["mahasiswa-bimbingan"],
    queryFn: fetchMhsList,
  });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["appointment"],
    queryFn: () => {
      const colRef = collection(db, process.env.REACT_APP_APPOINTMENT);
      const q = query(
        colRef,
        where("dosenid", "==", auth.currentUser.uid),
        where("status", "==", "menunggu")
      );
      return getDocs(q);
    },
    enabled: dataMhs ? true : false,
  });

  const deleteAppointment = async (appointmentId) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}jadwal/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointmentId,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isPending, mutate } = useMutation({
    mutationFn: deleteAppointment,
    onSuccess: () => {
      refetch();
    },
  });

  const tolakAppointment = async ({ appointmentId, mahasiswaid }) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}jadwal/tolak`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointmentId,
        mahasiswaid,
        nama: user.nama,
      }),
    });
    const da = await uu.json();
    return da;
  };

  const { isPending: pendingTolak, mutate: mutateTolak } = useMutation({
    mutationFn: tolakAppointment,
    onSuccess: () => {
      refetch();
    },
  });

  const setujuiAppointment = async ({ appointmentId, mahasiswaid }) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}jadwal/setujui`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointmentId,
        mahasiswaid,
        nama: user.nama,
      }),
    });
    const da = await uu.json();
    return da;
  };

  const { isPending: pendingSetujui, mutate: mutateSetujui } = useMutation({
    mutationFn: setujuiAppointment,
    onSuccess: () => {
      refetch();
    },
  });

  return {
    isLoading,
    data,
    isPending,
    mutate,
    dataMhs,
    pendingTolak,
    mutateTolak,
    pendingSetujui,
    mutateSetujui,
  };
}

export default useJadwalDosen;
