import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";

import useBimbinganMhs from "./useBimbinganMhs";
import UserContext from "../../../context/userContext";
import spinner from "../../../assets/spinner.gif";
import chat from "../../../assets/icon_chat.png";
import close from "../../../assets/icon_close_gray.png";

function BimbinganMhs() {
  const { setShowChat } = useContext(UserContext);
  const inputRef = useRef(null);
  const {
    data,
    mutate,
    isPending,
    judul,
    setJudul,
    doing,
    setDoing,
    isEditingJudul,
    setIsEditingJudul,
    isEditingDoing,
    setIsEditingDoing,
    setFile,
    pendingUpload,
    mutateUpload,
    isLoading,
    fileUrl,
    setFileUrl,
  } = useBimbinganMhs();

  const renderJudul = () => {
    if (isEditingJudul) {
      return (
        <>
          <textarea
            rows="5"
            className="w-full border border-blue-300 p-3 rounded-md bg-gray-100"
            value={judul}
            onChange={(e) => setJudul(e.target.value)}
          />
          <div className="flex justify-end mt-2">
            <button
              onClick={() => setIsEditingJudul(false)}
              className="border border-[#3b69fd] text-[#3b69fd] text-xs w-[100px] h-[34px] rounded mr-3 hover:shadow-md"
            >
              Batal
            </button>
            <button
              onClick={() => {
                if (!judul) return;
                mutate("judul");
              }}
              className="bg-biru text-white text-xs w-[100px] h-[34px] rounded hover:shadow-md"
            >
              {isPending ? (
                <img
                  className="w-[14px] mx-auto my-0"
                  src={spinner}
                  alt="menunggu"
                />
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <p
          onClick={() => {
            setIsEditingJudul(true);
            setJudul(data.data.judul);
          }}
          className="mb-4 text-gray-500  border-b border-gray-200 hover:border
     hover:border-blue-300 pb-3 rounded-md cursor-pointer"
        >
          {data.data.judul || "Klik untuk menambahkan/mengganti"}
        </p>
      );
    }
  };

  const renderDoing = () => {
    if (isEditingDoing) {
      return (
        <>
          <input
            value={doing}
            onChange={(e) => setDoing(e.target.value)}
            type="text"
            className="bg-gray-100 p-2 rounded w-full"
          />
          <div className="flex justify-end mt-2">
            <button
              onClick={() => setIsEditingDoing(false)}
              className="border border-[#3b69fd] text-[#3b69fd] text-xs w-[100px] h-[34px] rounded mr-3 hover:shadow-md"
            >
              Batal
            </button>
            <button
              onClick={() => {
                if (!doing) return;
                mutate("doing");
              }}
              className="bg-biru text-white text-xs w-[100px] h-[34px] rounded hover:shadow-md"
            >
              {isPending ? (
                <img
                  className="w-[14px] mx-auto my-0"
                  src={spinner}
                  alt="menunggu"
                />
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p
            onClick={() => {
              setIsEditingDoing(true);
              setDoing(data.data.doing);
            }}
            className={`mb-4 text-gray-500  border-b border-gray-200 hover:border
     hover:border-blue-300 pb-3 rounded-md cursor-pointer`}
          >
            {data.data.doing || "Klik untuk menambahkan/mengganti"}
          </p>
        </>
      );
    }
  };

  const renderCapaianBox = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    const activeIndex = data.data.capaian.findIndex(
      (item) => item.active === true
    );

    return data.data.capaian.map((item, index) => {
      return (
        <div
          key={item.title}
          className={`${
            index <= activeIndex ? "bg-biru" : "bg-gray-300"
          } h-[5px] rounded-full`}
          title={item.title}
        ></div>
      );
    });
  };

  const renderCapaianGrid = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    return (
      <div
        className={`grid grid-cols-${data.data.capaian.length} gap-1 w-full mb-4 border-b border-gray-200 pb-3`}
      >
        {renderCapaianBox()}
      </div>
    );
  };

  const renderCapaian = () => {
    if (!data.data.capaian || data.data.capaian.length < 1) {
      return;
    }
    const activeCapaian = data.data.capaian.filter(
      (item) => item.active === true
    );

    return <p className="pb-2 text-gray-500">{activeCapaian[0].title}</p>;
  };

  const renderDocUrl = () => {
    if (!data.data.docsUrl || data.data.docsUrl.length < 1) {
      return;
    }
    const arr = data.data.docsUrl[data.data.docsUrl.length - 1];
    return (
      <div>
        <Link
          target="_blank"
          to={arr.docUrl}
          className="underline text-blue-400"
        >
          {arr.name}
        </Link>
        <p className="text-xs">diunggah oleh: {arr.uploadedBy}</p>
      </div>
    );
  };

  const renderCatatan = () => {
    if (!data.data.catatan || data.data.catatan.length < 1) {
      return;
    }

    return data.data.catatan.map((item) => {
      return (
        <li key={item} className="">
          {item}
        </li>
      );
    });
  };

  if (isLoading) {
    return <p className="lg:w-1/2">Loading...</p>;
  }

  return (
    <>
      {data && data.data ? (
        <>
          <div className="lg:w-1/2">
            <div className="bg-white p-8 rounded-xl">
              <h1 className="font-semibold text-gray-500 mb-1">Judul</h1>
              {data && renderJudul()}

              <h1 className="font-semibold text-gray-500 mb-1">Capaian</h1>
              {data && renderCapaian()}
              {data && renderCapaianGrid()}

              <h1 className="font-semibold text-gray-500 mb-1 mt-3">
                Sedang Dikerjakan
              </h1>
              {data && renderDoing()}
              <h1 className="font-semibold text-gray-500 mb-1 mt-3">
                Dokumen Terbaru
              </h1>
              {data && renderDocUrl()}

              <div className="relative border border-gray-200 p-1 rounded-md mt-4 w-full">
                <button className="py-2">dd</button>
                <img
                  src={close}
                  alt="cancel"
                  className="absolute top-3 right-3 z-30 cursor-pointer"
                  onClick={() => {
                    setFile("");
                    inputRef.current.value = null;
                  }}
                />
                <label
                  htmlFor="upload"
                  className="top-[6px] left-1 absolute z-20 cursor-pointer bg-[#2690f2] text-white py-2 px-4 rounded text-xs inline-block hover:shadow-lg"
                >
                  Pilih dokumen
                </label>

                <input
                  ref={inputRef}
                  id="upload"
                  type="file"
                  className="custom-doc-input top-[8px] absolute left-2 lg:left-3 z-10 w-full"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <div className="mt-2">
                <p className="mb-1 text-xs text-slate-500">
                  Atau masukkan tautan (mis. Google Docs/Drive)
                </p>
                <input
                  type="text"
                  placeholder="Tempel tautan"
                  className="bg-gray-100 p-2 w-full text-[16px]"
                  onChange={(e) => setFileUrl(e.target.value)}
                  value={fileUrl}
                />
              </div>
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => {
                    if (pendingUpload) return;
                    mutateUpload(data.data.dosenUtama);
                  }}
                  className="bg-biru text-white w-[100px] h-[34px] rounded text-xs font-semibold hover:shadow-lg"
                >
                  {pendingUpload ? (
                    <img
                      className="w-[14px] mx-auto my-0"
                      src={spinner}
                      alt="menunggu"
                    />
                  ) : (
                    "Unggah"
                  )}
                </button>
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl mt-4 max-h-[30vh] overflow-y-auto">
              <h1 className="font-semibold text-gray-500 mb-1">
                Catatan singkat
              </h1>
              {data && renderCatatan()}
            </div>
            <div className="flex justify-end lg:hidden">
              <button
                onClick={() => setShowChat(true)}
                className="bg-[#6363c2] text-white text-xs px-4 py-3 flex items-center rounded-md mt-3 mr-3"
              >
                <img src={chat} alt="" className="" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <p className="lg:w-1/2">No data</p>
      )}
    </>
  );
}

export default BimbinganMhs;
