import { useQuery } from "@tanstack/react-query";
import { auth } from "../../../firebase";

function useBerandaMhs() {
  const getBimbinganDetails = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const da = await uu.json();
    return da;
  };
  const { isLoading, data } = useQuery({
    queryKey: ["data-bimbingan"],
    queryFn: getBimbinganDetails,
  });

  return {
    isLoading,
    data,
  };
}

export default useBerandaMhs;
