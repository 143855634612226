import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { style } from "../style";
import { auth } from "../../firebase";
import spinner from "../../assets/spinner.gif";
import errorImg from "../../assets/error.png";

function PasswordReset() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    if (!email) {
      return;
    }
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(
        `Tautan untuk mengatur ulang kata sandi telah dikirimkan ke ${email}`
      );
      setIsLoading(false);
    } catch (error) {
      // console.log(error.code);
      setIsLoading(false);
      switch (error.code) {
        case "auth/user-not-found":
          setError("Email tidak terdaftar");
          break;
        default:
          setError(error.code);
      }
    }
  };
  return (
    <div className="w-72 lg:w-96 mx-auto my-0 mt-10">
      <p className="font-bold text-lg text-slate-500 text-center mb-5">
        Atur ulang kata sandi
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <p className="text-slate-500 mb-2">Masukkan alamat email Anda</p>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className="border w-full border-gray-300 rounded-xl p-4"
          type="email"
          placeholder="Email"
          required
        />
        <button className={style.btnSubmit} type="submit">
          {isLoading ? (
            <img className="w-5 mx-auto my-0" src={spinner} alt="" />
          ) : (
            "Kirim"
          )}
        </button>
      </form>
      {error && (
        <div className="flex justify-center items-center border border-red-500 w-full  mx-auto my-0 mb-3 p-3 rounded-xl mt-5">
          <img className="mr-3" src={errorImg} alt="error" />
          <p className="text-red-400 text-sm">{error}</p>
        </div>
      )}
      {success && (
        <div className="border border-green-500 w-full  mx-auto my-0 mb-3 p-3 rounded-xl mt-5">
          <p className="text-slate-600 text-sm">{success}</p>
        </div>
      )}
    </div>
  );
}

export default PasswordReset;
