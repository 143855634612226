import React, { useState, useContext } from "react";
import usePengaturan from "./usePengaturan";
import Jadwal from "./Jadwal";
import Billing from "./Billing";
import UserContext from "../../../context/userContext";

import icon_edit from "../../../assets/icon_edit.png";
import icon_min from "../../../assets/icon_min.png";
import spinner from "../../../assets/spinner.gif";

function Pengaturan() {
  const { user } = useContext(UserContext);
  const [minimize, setMinimize] = useState(false);
  const {
    editProfil,
    setEditProfil,
    profil,
    setProfil,
    isPending,
    mutate,
    handleUpload,
    pendingBimbingan,
    mutateBimbingan,
    idbimbingan,
    setIdBimbingan,
    idTaken,
    editIdBimbingan,
    setEditIdBimbingan,
    updateBimbingan,
    pendingUpdate,
  } = usePengaturan();

  const renderProfile = () => {
    // if (!auth) {
    //   return;
    // }
    return (
      <div>
        <h1 className="font-semibold text-gray-600 mt-3">
          {user && user.nama}
        </h1>
        <p>
          {user && user.role === "dosen" ? "NIDN" : "NIM"}:{" "}
          {user && user.legalNumber}
        </p>
        <p className="mt-4 text-gray-500">{user && user.universitas}</p>
        <p className="">{user && user.fakultas}</p>
      </div>
    );
  };
  const handleSetProfil = (value, prop) => {
    setProfil((prev) => ({ ...prev, [prop]: value }));
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate();
        }}
        className="mt-5 text-[16px] lg:text-[14px]"
      >
        <input
          className="w-full bg-gray-100 px-3 py-2 rounded-lg mb-3"
          type="text"
          placeholder="Nama"
          value={profil.nama}
          onChange={(e) => handleSetProfil(e.target.value, "nama")}
        />
        <input
          className="w-full bg-gray-100 px-3 py-2 rounded-lg mb-3"
          type="text"
          placeholder={user.role === "dosen" ? "NIDN" : "NIM"}
          value={profil.legalNumber}
          onChange={(e) => handleSetProfil(e.target.value, "legalNumber")}
        />
        <input
          className="w-full bg-gray-100 px-3 py-2 rounded-lg mb-3"
          type="text"
          placeholder="Universitas"
          value={profil.universitas}
          onChange={(e) => handleSetProfil(e.target.value, "universitas")}
        />
        <input
          className="w-full bg-gray-100 px-3 py-2 rounded-lg"
          type="text"
          placeholder="Fakultas"
          value={profil.fakultas}
          onChange={(e) => handleSetProfil(e.target.value, "fakultas")}
        />
        <button type="submit"></button>
      </form>
    );
  };

  return (
    <>
      <h1 className="ml-4 lg:ml-0 mb-4 text-[16px] text-slate-600">
        Pengaturan
      </h1>
      <div className="lg:flex">
        <div
          className={`bg-white p-10 rounded-xl text-center lg:mr-10 w-full lg:w-[250px] xl:w-[300px] mb-5 lg:mb-auto relative overflow-hidden ${
            minimize && "h-[20px] pt-6 pb-8"
          }`}
        >
          <div
            onClick={() => setMinimize(!minimize)}
            className="absolute top-2 right-2 lg:hidden bg-gray-100 w-[33px] h-[33px] flex justify-center items-center rounded-full"
          >
            <img src={icon_min} alt="" />
          </div>
          <p
            className={`mb-3 text-gray-500 font-semibold ${
              !minimize && "hidden"
            }`}
          >
            Profile
          </p>

          <img
            src={(user && user.photoUrl) || "https://placehold.co/100"}
            alt="profile"
            className="rounded-full w-[100px] h-[100px] mx-auto my-0 object-cover"
          />
          <input
            type="file"
            className="custom-file-input py-2"
            accept="image/*"
            onChange={handleUpload}
          />
          {editProfil ? renderForm() : renderProfile()}
          {editProfil ? (
            <div className="mt-8">
              <button
                onClick={() => setEditProfil(false)}
                className="border border-[#3b69fd] hover:shadow-md text-[#3b69fd] text-xs font-semibold rounded-md mr-4 w-[80px] h-[34px]"
              >
                Batal
              </button>
              <button
                onClick={mutate}
                type="submit"
                className="border border-[#3b69fd] bg-biru hover:shadow-md text-white  text-xs font-semibold rounded-md mr-4 w-[80px] h-[34px]"
              >
                {isPending ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                if (user) {
                  setProfil({
                    nama: user.nama || "",
                    legalNumber: user.legalNumber || "",
                    universitas: user.universitas || "",
                    fakultas: user.fakultas || "",
                  });
                }
                setEditProfil(true);
              }}
              className="border border-[#3b69fd] hover:shadow-md text-[#3b69fd] text-xs px-4 h-[34px] font-semibold rounded-md mt-5"
            >
              Sunting Profil
            </button>
          )}
          {user && user.role === "dosen" && (
            <div className="border-t border-gray-200 mt-5 py-4 relative">
              <p className="text-blax font-semibold mb-5">ID Bimbingan</p>
              {user && user.idbimbingan && (
                <div className="flex justify-center items-center mb-3">
                  <p className="text-gray-400 text-[16px] mr-4">
                    {user.idbimbingan}
                  </p>
                  <img
                    onClick={() => setEditIdBimbingan(!editIdBimbingan)}
                    src={icon_edit}
                    alt="edit"
                    className="cursor-pointer"
                  />
                </div>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!idbimbingan) {
                    return;
                  }
                  if (!user.idbimbingan) {
                    mutateBimbingan();
                  }
                  if (user.idbimbingan && editIdBimbingan) {
                    //delete old data, set new one
                    updateBimbingan();
                  }
                }}
                className="text-[16px] lg:text-[14px]"
              >
                <p className="text-gray-500">
                  {!user.idbimbingan &&
                    "Silakan buat ID bimbingan (tanpa spasi)"}
                </p>
                {(!user.idbimbingan || editIdBimbingan) && (
                  <input
                    type="text"
                    placeholder="ID bimbingan"
                    className="bg-gray-100 p-4 rounded-lg mt-2 w-full"
                    onChange={(e) => setIdBimbingan(e.target.value)}
                  />
                )}

                {idTaken && (
                  <p className="text-right text-rose-500 text-xs mt-1">
                    ID sudah terpakai
                  </p>
                )}
                {editIdBimbingan && (
                  <button
                    onClick={() => setEditIdBimbingan(false)}
                    className="border border-[#3b69fd] hover:shadow-md text-[#3b69fd] text-xs font-semibold rounded-md w-[80px] h-[34px]"
                  >
                    Batal
                  </button>
                )}
                {(!user.idbimbingan || editIdBimbingan) && (
                  <button
                    type="submit"
                    className="bg-biru px-4 py-1 mt-3 mb-2 text-xs text-white rounded-md font-semibold hover:shadow-lg w-[80px] h-[34px] ml-4"
                  >
                    {pendingBimbingan || pendingUpdate ? (
                      <img
                        className="w-[14px] mx-auto my-0"
                        src={spinner}
                        alt="menunggu"
                      />
                    ) : (
                      "Simpan"
                    )}
                  </button>
                )}
              </form>
            </div>
          )}
        </div>

        {user && user.role === "dosen" && <Jadwal />}
        {user && user.role === "mahasiswa" && <Billing />}
      </div>
    </>
  );
}

export default Pengaturan;
