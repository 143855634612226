import React, { useState, useContext } from "react";
import UserContext from "../../../context/userContext";
import useBilling from "./useBilling";
import spinner from "../../../assets/spinner.gif";

function Billing() {
  const { aksesValid } = useContext(UserContext);
  const { isPending, mutate } = useBilling();
  const [kuponid, setKuponid] = useState("");
  return (
    <>
      {!aksesValid.subValid && (
        <div className="p-8 bg-white rounded-lg lg:w-2/3">
          <div className="mt-4 text-center">
            <p className="text-gray-500 mb-2">
              {aksesValid.trialValid
                ? "Anda masih menggunakan percobaan gratis"
                : "Anda belum melakukan pembayaran"}
            </p>
            {!aksesValid.trialValid && (
              <>
                <p>Klik tombol di bawah untuk memproses pembayaran</p>
                <p>Rp. 50.000,- untuk Pemakaian 6 bulan</p>
                <button className="bg-biru text-white text-xs font-semibold w-[100px] h-[34px] mt-4 rounded hover:shadow-lg">
                  Bayar
                </button>
              </>
            )}

            <div className="border-t border-gray-200 mt-5">
              <p className="mt-2">atau</p>
              <p>Gunakan kode kupon</p>
              <p className="mt-4 mb-4">
                Kode Kupon:{" "}
                <span className="bg-gray-100 px-4 py-1 text-gray-500 text-[16px] rounded">
                  beempro
                </span>
              </p>
              <input
                onChange={(e) => setKuponid(e.target.value)}
                type="text"
                placeholder="Masukkan kode kupon"
                className="bg-gray-100 px-4 py-3 rounded mt-3"
              />
              <button
                onClick={() => {
                  if (!kuponid) return;
                  mutate(kuponid);
                }}
                className="bg-[#6363c2] py-1 mt-3 mb-2 text-xs text-white rounded-md font-semibold hover:shadow-lg w-[130px] h-[34px] block mx-auto"
              >
                {isPending ? (
                  <img
                    className="w-[14px] mx-auto my-0"
                    src={spinner}
                    alt="menunggu"
                  />
                ) : (
                  "Gunakan Kupon"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Billing;
