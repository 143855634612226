import React, { useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { auth } from "../../../firebase";
import BimbinganDosen from "./BimbinganDosen";
import BimbinganMhs from "./BimbinganMhs";
import UserContext from "../../../context/userContext";
import Chat from "./Chat";
import ChatDosen from "./ChatDosen";
import dot from "../../../assets/icon_dot.png";
import close from "../../../assets/icon_close_gray.png";
import spinner from "../../../assets/spinner.gif";

function Bimbingan() {
  const { user, selectedMahasiswaId } = useContext(UserContext);
  const [showChat, setShowChat] = useState(false);
  const [showSelesai, setShowSelesai] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const selesaiBimbingan = async (value) => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}bimbingan/selesai`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mahasiswaid: selectedMahasiswaId.value,
      }),
    });
    const da = await uu.json();
    return da;
  };
  const { isPending, mutate } = useMutation({
    mutationFn: selesaiBimbingan,
    onSuccess: () => {
      window.location.reload(true);
    },
  });

  return (
    <div className="bg-[#fafafa] pb-5">
      <div className="ml-4 lg:ml-0 mt-2 mb-4 flex items-center">
        <h1 className=" text-[16px] text-slate-600">Bimbingan</h1>
        {user.role === "dosen" && (
          <div
            onClick={() => setShowSelesai(!showSelesai)}
            className="ml-5 bg-gray-100 w-[30px] h-[30px] flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-200 relative"
          >
            <img src={dot} alt="menu" />
            {showSelesai && (
              <div
                onClick={() => setShowPopup(true)}
                className="absolute top-8 left-0 bg-white p-4 z-20 rounded-md shadow-md hover:bg-gray-50"
              >
                <p className="w-[150px] text-center">Selesaikan bimbingan</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="lg:flex">
        {user && user.role === "dosen" && (
          <BimbinganDosen setShowChat={setShowChat} />
        )}

        {user && user.role === "mahasiswa" && (
          <BimbinganMhs setShowChat={setShowChat} />
        )}
        {user && user.role === "mahasiswa" && (
          <Chat showChat={showChat} setShowChat={setShowChat} />
        )}
        {user && user.role === "dosen" && (
          <ChatDosen showChat={showChat} setShowChat={setShowChat} />
        )}
      </div>

      {showPopup && user.role === "dosen" && (
        <div className="fixed top-0 left-0 w-[100vw] h-[100svh] lg:h-[100vh] z-30 bg-[rgba(0,0,0,0.6)] flex justify-center items-center">
          <div className="bg-white px-8 lg:w-auto w-[95vw] py-12 rounded-lg shadow-xl text-center relative">
            <img
              onClick={() => setShowPopup(false)}
              src={close}
              alt="tutup"
              className="absolute top-1 right-1 w-[30px] cursor-pointer"
            />
            {!selectedMahasiswaId.value ? (
              <p>Tidak ada data</p>
            ) : (
              <>
                <p className="mb-1">
                  Anda akan menyelesaikan bimbingan dengan{" "}
                  {selectedMahasiswaId.label}.
                </p>
                <p className="mb-4 text-gray-600">
                  Anda yakin {selectedMahasiswaId.label} sudah lulus/selesai
                  bimbingan dengan Anda?
                </p>
                <button
                  onClick={() => setShowPopup(false)}
                  className="w-[100px] h-[34px] rounded text-gray-400 hover:text-black font-semibold"
                >
                  Batalkan
                </button>
                <button
                  onClick={() => mutate()}
                  className="bg-biru text-white w-[100px] h-[34px] rounded font-semibold hover:shadow-lg"
                >
                  {isPending ? (
                    <img
                      className="w-[14px] mx-auto my-0"
                      src={spinner}
                      alt="menunggu"
                    />
                  ) : (
                    "Selesai"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Bimbingan;
