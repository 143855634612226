import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import { auth, db } from "../../../firebase";

function usePengajuan() {
  const [idbimbingan, setIdBimbingan] = useState("");
  const [idPendamping, setIdPendamping] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [idtobeDeleted, setIdtobeDeleted] = useState("");
  const [activeSearch, setActiveSearch] = useState("utama");

  const fetchUserPeng = async () => {
    const token = await auth.currentUser.getIdToken();
    const uu = await fetch(`${process.env.REACT_APP_API}pengajuan/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        role: "mahasiswa",
      }),
    });
    const da = await uu.json();
    return da;
  };
  const {
    isLoading,
    data: dataPeng,
    refetch: refetchPeng,
  } = useQuery({
    queryKey: ["fetch-userPeng"],
    queryFn: fetchUserPeng,
  });

  const searchBimbingan = async (type) => {
    const activeType = activeSearch === "utama" ? idbimbingan : idPendamping;
    const docSnap = await getDoc(
      doc(db, process.env.REACT_APP_BIMBINGANID, activeType)
    );
    if (!docSnap.data()) {
      toast.warning("ID tidak ditemukan", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      throw new Error("Id tidak ditemukan");
    } else {
      const docBimbingan = await getDoc(
        doc(db, process.env.REACT_APP_USER, docSnap.data().dosenid)
      );
      // console.log(docBimbingan.id);
      return docBimbingan;
    }
  };

  const {
    isPending,
    data: dataDosen,
    mutate,
  } = useMutation({
    mutationFn: searchBimbingan,
    onSuccess: () => {
      setShowResult(true);
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const handleSearchBimbingan = (e) => {
    e.preventDefault();
    if (!idbimbingan && !idPendamping) {
      return;
    }
    mutate();
  };

  const fetchAjukan = async (type) => {
    const token = await auth.currentUser.getIdToken();
    const fetchCreate = await fetch(
      `${process.env.REACT_APP_API}pengajuan/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          dosenid: dataDosen.id,
          type,
        }),
      }
    );
    const data = await fetchCreate.json();
    if (data.error) {
      throw new Error(data.message);
    } else {
      return data;
    }
  };

  const { isPending: pendingAjukan, mutate: mutateAjukan } = useMutation({
    mutationFn: fetchAjukan,
    onSuccess: () => {
      setShowResult(false);
      refetchPeng();
    },
    onError: (error) => {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });

  const { isPending: pendingDelete, mutate: mutateDelete } = useMutation({
    onMutate: (dosenid) => {
      setIdtobeDeleted(dosenid);
    },
    mutationFn: async (dosenid) => {
      const token = await auth.currentUser.getIdToken();
      const uu = await fetch(`${process.env.REACT_APP_API}pengajuan/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          dosenid,
        }),
      });
      const da = await uu.json();
      return da;
    },
    onSuccess: () => {
      setIdtobeDeleted("");
      refetchPeng();
    },
    onError: () => {
      setIdtobeDeleted("");
    },
  });

  return {
    isPending,
    dataDosen,
    setIdBimbingan,
    handleSearchBimbingan,
    pendingAjukan,
    mutateAjukan,
    showResult,
    dataPeng,
    isLoading,
    pendingDelete,
    mutateDelete,
    idtobeDeleted,
    activeSearch,
    setActiveSearch,
    idbimbingan,
    setShowResult,
    idPendamping,
    setIdPendamping,
    // testTime,
  };
}

export default usePengajuan;
