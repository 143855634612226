import React, { useState, useContext, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

import Notifikasi from "./Notifikasi";
import UserContext from "../../context/userContext";

import bell from "../../assets/icon_bell.png";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import pengaturan from "../../assets/icon_pengaturan.png";
// import ticket from "../../assets/icon_kado.png";

function Topmenu() {
  const { user } = useContext(UserContext);
  const [stillUnread, setStillUnread] = useState(false);
  const [notifData, setNotifData] = useState([]);
  const [showNotif, setShowNotif] = useState(false);
  const [docListenerAdded, setDocListenerAdded] = useState(false);
  useEffect(() => {
    const colRef = collection(
      db,
      process.env.REACT_APP_USER,
      auth.currentUser.uid,
      "notification"
    );

    const unsubscribe = onSnapshot(
      query(colRef, orderBy("created", "desc"), limit(10)),
      (snapshot) => {
        let notifikasi = [];
        snapshot.docs.forEach((doc) => {
          notifikasi.push({ ...doc.data(), id: doc.id });
        });
        notifikasi.find((item) => {
          if (!item.isread) {
            setStillUnread(true);
            return true;
          }
          setStillUnread(false);
          return false;
        });
        setNotifData(notifikasi);
      }
    );
    return () => {
      unsubscribe();
    };
    //eslint-disable-next-line
  }, []);

  const notifState = () => {
    setShowNotif(!showNotif);
  };
  const onClickOutside = () => {
    setShowNotif(false);
  };
  const setListener = (arg) => {
    setDocListenerAdded(arg);
  };
  const navigate = useNavigate();
  const signOutUser = async () => {
    try {
      await signOut(auth);
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-4 lg:px-8 py-1 lg:py-2 flex fixed top-0 left-0 w-full flex-row-reverse items-center border-b border-gray-300 lg:border-none bg-white shadow lg:shadow-none lg:bg-[#fafafa] z-10">
      <button
        onClick={signOutUser}
        className="text-rose-400 lg:text-regular font-semibold rounded-lg ml-3  hover:underline"
      >
        Keluar
      </button>
      <NavLink to="/app/pengaturan" className="act">
        <div className="hover:bg-gray-200 rounded-full cursor-pointer transition ease-in-out delay-30 pengaturan w-[35px] h-[35px] flex items-center ml-3 justify-center act">
          <img src={pengaturan} alt="notifikasi-icon" className="w-[20px]" />
        </div>
      </NavLink>
      {/* <NavLink to="/app/reward" className="act">
        <div className="hover:bg-gray-200 rounded-full cursor-pointer transition ease-in-out delay-30 pengaturan w-[35px] h-[35px] flex items-center justify-center ml-3">
          <img src={ticket} alt="notifikasi-icon" className="w-[20px]" />
        </div>
      </NavLink> */}
      <p className="ml-3 text-gray-600 hidden lg:block">{user && user.nama}</p>
      <div
        onClick={() => {
          if (docListenerAdded) {
            return;
          }
          notifState();
        }}
        className="hover:bg-gray-200 flex items-center justify-center w-[35px] h-[35px] rounded-full cursor-pointer transition ease-in-out delay-30 relative ml-3"
      >
        <img src={bell} alt="notifikasi-icon" className="h-[22px]" />
        {stillUnread && (
          <div className="w-[10px] h-[10px] bg-blue-400 absolute top-1 right-2 rounded-full"></div>
        )}
      </div>

      {showNotif && (
        <Notifikasi
          onClickOutside={onClickOutside}
          setListener={setListener}
          notifData={notifData}
        />
      )}
    </div>
  );
}

export default Topmenu;
