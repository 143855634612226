import React, { useState } from "react";
import dayjs from "dayjs";

import useNotifikasi from "./useNotifikasi";

import trash from "../../../assets/icon_trash.png";

function SemuaNotifikasi() {
  const { mutate, data, fetchNextPage } = useNotifikasi();
  const [selectedNotif, setSelectedNotif] = useState([]);

  const renderAllNotif = () => {
    if (data.length < 1) {
      return;
    }
    if (data.length > 0) {
      return data.map((document) => {
        // console.log(new Date(document.created).toISOString());
        return (
          <div
            key={document.id}
            className="py-4 border-b border-gray-100 flex items-center"
          >
            <input
              onChange={(e) => {
                if (selectedNotif.includes(document.id)) {
                  const filtered = selectedNotif.filter(
                    (id) => id !== document.id
                  );
                  setSelectedNotif(filtered);
                } else {
                  setSelectedNotif((prev) => [...prev, document.id]);
                }
              }}
              type="checkbox"
              className="mr-4"
              checked={false || selectedNotif.includes(document.id)}
            />
            <div>
              <p>{document.msg}</p>
              <p className="text-gray-500">
                {dayjs(document.created).format("D MMMM, HH:mm")}
                {/* {dayjs(document.created, "M/D/YYYY, h:mm:ss A", "id").format(
                  "D MMMM YYYY, HH:mm"
                )} */}
              </p>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className="">
      <div className="flex items-center  mb-4">
        <h1 className="mx-4 lg:ml-4 text-slate-600 text-[16px]">Notifikasi</h1>
      </div>
      <div className=" ml-4  mb-4 flex items-center">
        <input
          type="checkbox"
          className="mr-4"
          onChange={(e) => {
            if (e.target.checked) {
              const allId = [];
              data.forEach((item) => {
                allId.push(item.id);
              });

              setSelectedNotif(allId);
            } else {
              setSelectedNotif([]);
            }

            // console.log(selectedNotif);
          }}
        />
        <img
          onClick={async () => {
            if (selectedNotif.length < 1) {
              return;
            }
            mutate(selectedNotif);
          }}
          src={trash}
          alt=""
          className="cursor-pointer"
        />
      </div>
      <div className="bg-white px-8 py-4 rounded-md shadow max-h-[60vh] overflow-y-auto">
        {data && renderAllNotif()}
      </div>
      <button
        onClick={() => {
          fetchNextPage();
        }}
        className="bg-biru text-white mt-2 rounded-md hover:shadow w-[100px] h-[34px] text-xs font-semibold mx-auto my-0 block"
      >
        Load More
      </button>
    </div>
  );
}

export default SemuaNotifikasi;
