import React from "react";
import usePengajuanDosen from "./usePengajuanDosen";
import spinner from "../../../assets/spinner.gif";
import box from "../../../assets/icon_box.png";

function PengajuanDosen() {
  const {
    data,
    isPending,
    mutate,
    idtobeDeleted,
    idtobeAccepted,
    isPendingTerima,
    mutateTerima,
    isLoading,
  } = usePengajuanDosen();

  const renderPengajuanDosen = () => {
    if (data.length < 1) {
      return (
        <div className="mt-4 text-center shadow py-8">
          <img src={box} alt="" className="w-[40px] mx-auto my-0 mb-3" />
          <p className="text-gray-400 font-semibold">Tidak ada data</p>
        </div>
      );
    }
    return data.map((item) => {
      return (
        <div
          key={item.mahasiswaid}
          className="lg:grid grid-cols-3 gap-2 items-center border-b border-gray-100 py-4 text-center"
        >
          <div className="lg:flex items-center">
            <img
              src={item.photoUrl || "https://placehold.co/80"}
              alt="profil"
              className="w-[80px] h-[80px] rounded-full object-cover lg:mr-5 mx-auto my-0 lg:mx-0"
            />
            <p className="text-blax font-semibold">{item.nama}</p>
          </div>

          <div className="mb-4 lg-mb:0">
            <p className="font-semibold text-gray-600 mb-1">
              NIM: {item.legalNumber}
            </p>
            <p className="text-gray-500">{item.fakultas}</p>
            <p className="text-[#3b69fd] font-semibold">{item.universitas}</p>
          </div>
          <div>
            <button
              onClick={() => {
                mutate(item.mahasiswaid);
              }}
              className="border border-rose-400 hover:shadow-md text-rose-400  text-xs font-semibold rounded-lg mr-4 w-[80px] h-[34px]"
            >
              {isPending && idtobeDeleted === item.mahasiswaid ? (
                <img
                  className="w-[14px] mx-auto my-0"
                  src={spinner}
                  alt="menunggu"
                />
              ) : (
                "Tolak"
              )}
            </button>
            <button
              onClick={() => {
                mutateTerima(item.mahasiswaid);
              }}
              className="border border-[#3b69fd] text-[#3b69fd] rounded-lg text-xs font-semibold w-[80px] h-[34px] hover:shadow-lg"
            >
              {isPendingTerima && idtobeAccepted === item.mahasiswaid ? (
                <img
                  className="w-[14px] mx-auto my-0"
                  src={spinner}
                  alt="menunggu"
                />
              ) : (
                "Terima"
              )}
            </button>
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <h1 className="ml-4 lg:ml-0 mb-4 text-[16px] text-slate-600">
        Pengajuan
      </h1>
      {isLoading && <p className="text-gray-300 italic">Loading...</p>}

      {data && (
        <div>
          <div className="bg-white px-8 pt-4 pb-8 rounded-xl max-w-[1280px] mx-auto my-0">
            {renderPengajuanDosen()}
          </div>
        </div>
      )}
    </>
  );
}

export default PengajuanDosen;
