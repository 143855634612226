function useAccess() {
  function addDays(date, days) {
    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + days);
    return dateCopy;
  }
  function user_access(user) {
    if (!user) return;
    // console.log(user);
    const trialStart = user.trialStart.toDate();
    const trialExpiry = addDays(trialStart, 30);
    const trialValid = Date.now() < trialExpiry.getTime();

    const subValid = () => {
      if (!user.subscriptionStart) {
        return false;
      }
      const subscriptionStart = user.subscriptionStart.toDate();
      const subscriptionExpiry = addDays(subscriptionStart, 185);
      return Date.now() < subscriptionExpiry;
    };

    return {
      trialValid,
      subValid: subValid(),
    };
  }

  return {
    user_access,
  };
}

export default useAccess;
