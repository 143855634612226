import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../../firebase";

import { style } from "../style";
import mail from "../../assets/icon_mail.png";
import password from "../../assets/password.png";
import spinner from "../../assets/spinner.gif";

function SigninForm({ setError }) {
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const handleSetUser = (val, prop) => {
    setUser((prev) => ({ ...prev, [prop]: val }));
  };
  const fetchSignin = () => {
    return signInWithEmailAndPassword(auth, user.email, user.password);
  };
  const { isPending, mutate } = useMutation({
    mutationFn: fetchSignin,
    onSuccess: () => {
      navigate("/app/beranda");
    },
    onError: (error) => {
      console.log(error.code);
      switch (error.code) {
        case "auth/user-not-found":
          setError("Email tidak terdaftar");
          break;
        case "auth/wrong-password":
          setError("Password salah");
          break;
        default:
          setError(error.code);
      }
    },
  });
  const handleSignin = (e) => {
    e.preventDefault();
    if (!user.email || !user.password) {
      return;
    }
    mutate();
  };

  return (
    <form onSubmit={handleSignin} className="w-72 lg:w-96 mx-auto my-0">
      <div className="relative mb-3">
        <img
          className="absolute left-4 top-1/2 -translate-y-1/2"
          src={mail}
          alt="mail"
        />
        <input
          className="border w-full border-gray-300 pl-16 rounded p-4"
          type="text"
          placeholder="Email"
          required
          onChange={(e) => handleSetUser(e.target.value, "email")}
        />
      </div>
      <div className="relative">
        <img
          className="absolute left-4 top-1/2 -translate-y-1/2"
          src={password}
          alt="key"
        />
        <input
          className="border w-full border-gray-300 pl-16 rounded p-4"
          type="password"
          placeholder="Kata sandi"
          required
          onChange={(e) => handleSetUser(e.target.value, "password")}
        />
      </div>
      <button type="submit" className={style.btnSubmit}>
        {isPending ? (
          <img className="w-5 mx-auto my-0" src={spinner} alt="" />
        ) : (
          "Masuk"
        )}
      </button>
      <Link
        to="/passwordreset"
        target="_blank"
        className="block text-blue-500 text-sm text-right mt-2"
      >
        Lupa kata sandi
      </Link>
    </form>
  );
}

export default SigninForm;
